import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import Component from "./component";

interface IProps {
  onChangeActiveStep: (step: number) => void;
}

const LandingWelcomeScreen = ({ onChangeActiveStep }: IProps) => {
  const mainBGImageSrc = useSelector(
    (state: RootState) => state.media.landingImageURL
  );

  return (
    <Component
      imgSrc={mainBGImageSrc}
      onChangeActiveStep={onChangeActiveStep}
    />
  );
};

export default LandingWelcomeScreen;
