import { Box, Button, Stack } from "@mui/material";
import SuccessIconSVG from "assets/Landing/BookForm/successIcon.svg";
import { IBookFormProps } from "models/Landing";
import React from "react";
import { StyledTextContainer, StyledThankYouScreenContainer } from "./styles";

interface IProps {
  formValues: IBookFormProps;
  onClose: () => void;
}

const ThankYouScreen = ({ formValues, onClose }: IProps) => (
  <StyledThankYouScreenContainer spacing={"4vh"}>
    <SuccessIconSVG />
    <Stack alignItems="center">
      <StyledTextContainer>Thank you {formValues.name}!</StyledTextContainer>
      <StyledTextContainer>Your appointment is confirmed</StyledTextContainer>
      <Box className="smallText">
        Check your inbox | phone to find the appointment scheduled
      </Box>
    </Stack>
    <Button variant="contained" onClick={onClose}>
      Continue
    </Button>
  </StyledThankYouScreenContainer>
);

export default ThankYouScreen;
