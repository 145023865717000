import { ICarConfigProps } from "models/WebGLConfigurator";
import React from "react";
import { useDispatch } from "react-redux";
import { setCarOptionConfig } from "store/reducers/WebGLConfigurator";
import Component from "./component";

interface IProps {
  availableOptions: any;
  changeVariant: (item: any) => void;
  config: any;
  currentEditable: string;
}

const UISubmenuItems = ({
  availableOptions,
  changeVariant,
  config,
  currentEditable
}: IProps) => {
  const dispatch = useDispatch();
  const currSelectedItem = config.find(
    (item: { id: string; carConfig: { [x: string]: string } }) =>
      item.id === currentEditable
  )?.carConfig;
  const handleOnChangeVariant = (item: any) => {
    dispatch(
      setCarOptionConfig({ [currentEditable]: item } as ICarConfigProps)
    );
    changeVariant(item);
  };
  return (
    <Component
      availableOptions={availableOptions}
      currSelectedItem={currSelectedItem}
      onClick={handleOnChangeVariant}
    />
  );
};

export default UISubmenuItems;
