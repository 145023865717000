import { UseCurrentCarConfigReturnType } from "components/WebGLConfigurator/WebGL";
import { CameraShot, CarModel, Environment } from "contexts/ConfigContext";
import { MVStartRenderOptions } from "mv-webgl-core/models/camera";
import React, { useCallback, useEffect, useState } from "react";
import Component from "./component";

interface ConfiguratorControlsProps {
  carModel: CarModel;
  loadEnvironment: (
    name: string,
    configCode: string,
    startRenderOptions?: MVStartRenderOptions
  ) => Promise<void>;
  loadCamera: (name: string) => Promise<void>;
  loadCar: (
    configCodes: string[],
    startRenderOptions?: MVStartRenderOptions
  ) => Promise<void>;
  useCurrentCarConfig: () => UseCurrentCarConfigReturnType;
}

const ConfiguratorControls: React.FC<ConfiguratorControlsProps> = props => {
  const { carModel, loadCamera, loadCar, useCurrentCarConfig } = props;

  const [currentEnvironment, setCurrentEnvironment] = useState<Environment>();
  const [currentCamera, setCurrentCamera] = useState<CameraShot>();

  useEffect(() => {
    const defaultEnvironment = carModel.environments.find(
      env => env.default === true
    );

    if (defaultEnvironment === undefined) {
      throw new Error(`Couldn't find a default environment!
        Add property "default": true to the default entry in "environments" in the config.`);
    }

    setCurrentEnvironment(defaultEnvironment);
    const defaultCamera = carModel.cameraShots.find(
      camera => camera.default === true
    );

    if (defaultCamera === undefined) {
      throw new Error(`Couldn't find a default camera!
        Add property "default": true to the default entry in "cameraShots" in the config.`);
    }

    setCurrentCamera(defaultCamera);
  }, [carModel, setCurrentEnvironment, setCurrentCamera]);

  const loadCameraCallback = useCallback(
    (cameraShotName: string) => {
      const camera = carModel.cameraShots.find(
        cam => cam.name === cameraShotName
      );

      if (!camera) {
        throw new Error(
          `Couldn't find camera shot with name "${cameraShotName}"!`
        );
      }

      setCurrentCamera(camera);
      loadCamera(camera.name);
    },
    [carModel.cameraShots, loadCamera]
  );

  return (
    <Component
      {...{
        carModel,
        loadCar,
        loadCamera: loadCameraCallback,
        currentCamera,
        useCurrentCarConfig,
        currentEnvironment
      }}
    />
  );
};

export default ConfiguratorControls;
