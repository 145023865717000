import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "models/Email";

export const EmailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    sendEmail: () => ({
      isLoading: true
    }),
    sendEmailSuccess: () => initialState
  }
});

export const { sendEmail, sendEmailSuccess } = EmailSlice.actions;

export default EmailSlice.reducer;
