import { ReactNode } from "react";

export interface IBrochureImagesProps {
  readonly mainScreenImageURL: string;
  readonly detailsImagesURL: string[];
  readonly interiorImageURL: string;
  readonly rimsImageURL: string;
  readonly frontViewImageURL: string;
}

export interface IBrochureCarPropertiesProps {
  title: string;
  icon: ReactNode;
  value: number;
  measure: string;
}

export interface IBrochureSlide {
  count: number;
}

export interface IBrochureSlideContainerProps extends IBrochureSlide {
  bgSrc?: string;
  type?: string;
  flipped?: boolean;
  mask: { width: number; height: number };
}

export interface IBrochureSlideSideLabelProps extends IBrochureSlide {
  side: "left" | "right";
}

export const initialState: IBrochureImagesProps = {
  mainScreenImageURL: "",
  detailsImagesURL: [],
  interiorImageURL: "",
  rimsImageURL: "",
  frontViewImageURL: ""
};
