import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { switchIframeMode } from "store/reducers/ViewMode";
import Component from "./component";
import { publishModelState } from "../../api/unreal-showroom";

const Appointment = () => {
  const dispatch = useDispatch();

  const name =
    useSelector((state: RootState) => state.bookForm.name).toUpperCase() ||
    "JANE";
  const carConfig = useSelector(
    (state: RootState) => state.webGLConfigurator.carConfig
  );
  const videoSrc = useSelector((state: RootState) => state.media.videoURL);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [exploreMode, setExploreMode] = useState(false);
  const [isIframeLoading, setIsIframeLoading] = useState(false);

  const handleOnChangeMode = () => {
    if (!exploreMode) {
      publishModelState(carConfig).then(() => {
        dispatch(switchIframeMode());
        setExploreMode(true);
        setIsIframeLoading(true);
      });
    }
  };

  const handleOnIframeLoaded = () => setIsIframeLoading(false);

  const handleOnOpenCloseVideo = () => setIsVideoOpen(!isVideoOpen);

  return (
    <Component
      name={name}
      exploreMode={exploreMode}
      isIframeLoading={isIframeLoading}
      isVideoOpen={isVideoOpen}
      onChangeMode={handleOnChangeMode}
      onIframeLoaded={handleOnIframeLoaded}
      onOpenCloseVideo={handleOnOpenCloseVideo}
      videoSrc={videoSrc}
    />
  );
};

export default Appointment;
