import { APP_ROUTES } from "constants/routing";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import Component from "./component";

const Advert = () => {
  const navigate = useNavigate();

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const imgUrl = useSelector((state: RootState) => state.media.advertImageURL);

  const handleOnImageLoaded = () => setIsImageLoaded(true);
  const handleNavigate = () => navigate(APP_ROUTES.LANDING);

  return (
    <Component
      imgUrl={imgUrl}
      isImageLoaded={isImageLoaded}
      onImageLoad={handleOnImageLoaded}
      onNavigate={handleNavigate}
    />
  );
};

export default Advert;
