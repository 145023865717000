import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { bookFormInitialState, IBookFormProps } from "models/Landing";

export const BookFormSlice = createSlice({
  name: "bookForm",
  initialState: bookFormInitialState,
  reducers: {
    setFormValues: (state, action: PayloadAction<IBookFormProps>) => ({
      ...state,
      ...action.payload
    }),
    resetFormValues: () => bookFormInitialState
  }
});

export const { setFormValues, resetFormValues } = BookFormSlice.actions;

export default BookFormSlice.reducer;
