import { IMAGE_CAR_COLORS, IMAGE_CAR_RIMS } from "constants/images";
import { ICarConfigProps } from "models/WebGLConfigurator";
import {
  blackNinja,
  blackShuriken,
  redNinja,
  redShuriken,
  yellowNinja,
  yellowShuriken
} from "../../../public/assets/video";

export const getVideoByConfig = (config: ICarConfigProps) => {
  const color = config.carpaint?.urlValue;
  const rims = config.rims?.urlValue;
  switch (color) {
    case IMAGE_CAR_COLORS.BLACK:
      return rims === IMAGE_CAR_RIMS.SPORTS_CAR_BASIS
        ? blackNinja
        : blackShuriken;
    case IMAGE_CAR_COLORS.RED:
      return rims === IMAGE_CAR_RIMS.SPORTS_CAR_BASIS ? redNinja : redShuriken;
    case IMAGE_CAR_COLORS.YELLOW:
      return rims === IMAGE_CAR_RIMS.SPORTS_CAR_BASIS
        ? yellowNinja
        : yellowShuriken;
  }
};
