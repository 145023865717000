import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import Component from "./component";

interface IProps {
  forceStop: boolean;
  videoSrc?: string;
  posterSrc?: string;
}

const VideoPlayer = ({ forceStop, videoSrc, posterSrc }: IProps) => {
  let videoRef = useRef<HTMLVideoElement>(null);

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isSeeking, setIsSeeking] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [time, setTime] = useState<number>(0);
  const [volume, setVolume] = useState<number>(1);
  const [prevVolumeValue, setPrevVolumeValue] = useState<number>(100);
  const [duration, setDuration] = useState<number>(0);
  const [currentInterval, setCurrentInterval] = useState<any>(undefined);

  useEffect(() => {
    if (forceStop) handleOnStop();
  }, [forceStop]);

  useEffect(() => {
    if (isPlaying && !isSeeking) {
      const interval = setInterval(() => {
        if (videoRef.current) {
          setCurrentInterval(interval);
          setVolume(videoRef.current.volume * 100);
          setTime(videoRef.current.currentTime);
        }
      }, 300);
    } else clearInterval(currentInterval);
  }, [isPlaying, isSeeking]);

  const handleLoadMetaData = (value: SyntheticEvent<HTMLVideoElement>) => {
    setDuration(value.currentTarget.duration);
  };

  const handleOnPlayPause = () => {
    isPlaying ? videoRef.current?.pause() : videoRef.current?.play();
    setIsPlaying(!isPlaying);
  };

  const handleChangeTime = (_: any, value: number | number[]) => {
    if (videoRef.current) {
      setIsSeeking(true);
      videoRef.current.pause();
      videoRef.current.currentTime = value as number;
      setTime(value as number);
    }
  };

  const handleChangeTimeCommitted = () => {
    setIsSeeking(false);
    videoRef.current && isPlaying && videoRef.current.play();
  };

  const handleChangeVolume = (_: any, value: number | number[]) => {
    if (videoRef.current) videoRef.current.volume = (value as number) / 100;
    setVolume(value as number);
    setIsMuted(!value);
  };

  const timeValueLabelFormat = (value: number) => {
    const roundedValue = Math.round(value);
    const minute = Math.floor(roundedValue / 60);
    const secondLeft = roundedValue - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  };

  const volumeValueLabelFormat = (val: number) => `${Math.round(val)}%`;

  const handleOnMuteUnmute = () => {
    if (videoRef.current)
      videoRef.current.volume = isMuted ? prevVolumeValue / 100 || 0.5 : 0;
    setIsMuted(!isMuted);
    setPrevVolumeValue(volume);
  };

  const handleOnStop = () => {
    videoRef.current && videoRef.current.pause();
    setIsPlaying(false);
    setIsMuted(false);
    setTime(0);
    setVolume(1);
    setPrevVolumeValue(100);
    setDuration(0);
  };

  return (
    <Component
      duration={duration}
      isMuted={isMuted}
      isPlaying={isPlaying}
      onChangeTime={handleChangeTime}
      onChangeTimeCommitted={handleChangeTimeCommitted}
      onChangeVolume={handleChangeVolume}
      onLoadMetaData={handleLoadMetaData}
      onMuteUnmute={handleOnMuteUnmute}
      onPlayPause={handleOnPlayPause}
      posterSrc={posterSrc}
      videoRef={videoRef}
      time={time}
      timeValueLabelFormat={timeValueLabelFormat}
      videoSrc={videoSrc}
      volume={volume}
      volumeValueLabelFormat={volumeValueLabelFormat}
    />
  );
};

export default VideoPlayer;
