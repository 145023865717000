import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useSVGSizes } from "utils/resizeCustomHook";
import useToggledView from "utils/toggleCustomHook";
import Component from "./component";

const Layout = () => {
  const { slider, toggleButton } = useSVGSizes();

  const { toggledView } = useToggledView();

  const { animationDirection, iFrameMode } = useSelector(
    (state: RootState) => state.viewMode
  );

  return (
    <Component
      animationDirection={animationDirection}
      iFrameMode={iFrameMode}
      marginBottom={toggleButton.marginBottom}
      toggledView={toggledView}
      sliderHeight={slider.height}
      sliderWidth={slider.width}
    />
  );
};

export default Layout;
