import { Box, Fab, Dialog } from "@mui/material";
import { StyledCloseButton } from "components/ToggledView/styles";
import CloseIcon from "@mui/icons-material/Close";
import HorizLineSVG from "assets/Home/hor_line_home.svg";
import LogoSVG from "assets/Home/mv_logo.svg";
import { MousePositionModel } from "models/Home";
import React, { useState } from "react";

import {
  StyledBg,
  StyledBgWithMask,
  StyledContentBox,
  StyledFooter,
  StyledMainTextContainer,
  StyledSecondaryTextContainer,
  StyledVerticalLineContainer,
  StyledDialogTextContainer,
  StyledDialogHeaderContainer
} from "./styles";

interface IProps {
  mousePosition: MousePositionModel;
  isPopupOpen: boolean;
  onEnter: () => void;
  onOpenClosePopup: () => void;
}

const Component = ({ mousePosition, isPopupOpen, onEnter, onOpenClosePopup}: IProps) => (
  <Box>

    <StyledBg />
    <StyledBgWithMask mousePosition={mousePosition} />
    <StyledContentBox>
      <StyledSecondaryTextContainer>
        See how <b>Accenture</b> delivers
      </StyledSecondaryTextContainer>
      <StyledMainTextContainer>Next-Gen Content</StyledMainTextContainer>
      <StyledSecondaryTextContainer>
        to an Automotive Omnichannel Experience
      </StyledSecondaryTextContainer>
      <StyledVerticalLineContainer>
        <HorizLineSVG viewBox="0 0 2 85" />
      </StyledVerticalLineContainer>

      <Fab onClick={onEnter}>Enter</Fab>
    </StyledContentBox>
    <StyledFooter>
      <Box>
        <LogoSVG viewBox="0 0 36 36" />
        Next-Gen Content powered by SynOps
      </Box>
      <Box>© 2022 Accenture. All Rights Reserved.</Box>
    </StyledFooter>
    <Dialog open={isPopupOpen} fullWidth maxWidth="md">
      <StyledDialogTextContainer>
      <StyledDialogHeaderContainer>
        Purpose of the Next-Gen Content Demo<br></br>
      </StyledDialogHeaderContainer>
      <br></br>
      Kindly note that this demo is intended to provide an overview of the potential of Next-Gen Content along the customer journey. It is not a plug-and-play solution and it does not claim to be exhaustive.<br></br>
      <br></br>
      We highly recommend to present this demo to the senior-most marketing & sales leader of a company. They are better equipped to assess the strategic value of this technology for their business.<br></br>
      <br></br>
      Furthermore, we would like to emphasize that this demo is solely intended for potential client conversations. It should not be shared directly with clients or any third party without our prior approval.<br></br>
      <br></br>
      We understand that each client's customer journey is unique and may require customized solutions. Our team of experts is always available to work with you to create bespoke solutions that meet your client's specific needs.<br></br>
      <br></br>
      Please do not hesitate to contact us via <b>nextgencontent@accenture.com</b> if you have any inquiries or wish to explore how "Next-Gen-Content" might benefit your clients.
  </StyledDialogTextContainer>
      <StyledCloseButton color="secondary" onClick={onOpenClosePopup}>
        <CloseIcon />
      </StyledCloseButton>
    </Dialog>
  </Box>
  
);

export default Component;
