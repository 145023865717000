import CloseIcon from "@mui/icons-material/Close";
import { APP_ROUTES } from "constants/routing";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { setFormValues } from "store/reducers/BookForm";
import { predefinedState } from "./config";
import FormScreen from "./FormScreen";
import {
  StyledBookFormCloseButton,
  StyledBookFormContainer,
  StyledBookFormWrapper
} from "./styles";
import ThankYouScreen from "./ThankYouScreen";

interface IProps {
  onBookFormOpenClose: () => void;
}

const BookForm = ({ onBookFormOpenClose }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [booked, setBooked] = useState(false);
  const formValues = useSelector((state: RootState) => state.bookForm);

  const handleAutoFill = () => {
    if (!isFormValid) {
      dispatch(setFormValues(predefinedState));
      setChecked(true);
    }
  };

  const handleFormValuesChange = (value: { [x: string]: string }) =>
    dispatch(setFormValues({ ...formValues, ...value }));

  const handleOnClose = () =>
    booked ? navigate(APP_ROUTES.APPOINTMENT) : onBookFormOpenClose();

  const handleOnCheck = () => setChecked(!checked);

  const handleBookAppointment = () => setBooked(true);

  const isFormValid = useMemo(
    () => Object.values({ ...formValues, selectedItem: "" }).some(val => val),
    [formValues]
  );

  return (
    <StyledBookFormWrapper>
      <StyledBookFormContainer small={booked}>
        <StyledBookFormCloseButton color="secondary" onClick={handleOnClose}>
          <CloseIcon color="action" />
        </StyledBookFormCloseButton>
        {booked ? (
          <ThankYouScreen onClose={handleOnClose} formValues={formValues} />
        ) : (
          <FormScreen
            onAutoFill={handleAutoFill}
            onFormValueChange={handleFormValuesChange}
            onCheck={handleOnCheck}
            checked={checked}
            formValues={formValues}
            onBook={handleBookAppointment}
            isFormValid={isFormValid}
          />
        )}
      </StyledBookFormContainer>
    </StyledBookFormWrapper>
  );
};

export default BookForm;
