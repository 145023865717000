import { SLIDES } from "constants/brochure";

require("assets/mask.svg");

export const getBrochureSlideBackgroundProps = (
  type?: string,
  maskSize?: { width: number; height: number }
) => {
  const defaultProps = {
    backgroundPosition: "center",
    backgroundSize: "cover"
  };
  switch (type) {
    case SLIDES.MAIN.type:
      return {
        ...defaultProps,
        mask: "linear-gradient(to top,transparent 10%, black 20%)"
      };
    case SLIDES.INTERIOR.type:
      return {
        ...defaultProps,
        mask:
          "linear-gradient(transparent 20%, black 40%, black 80%, transparent 100%)"
      };
    case SLIDES.RIMS.type:
      return {
        backgroundPosition: "right",
        backgroundSize: "60%",
        mask:
          "radial-gradient(ellipse at 100%, black 35%, rgba(0, 0, 0, 0) 40%)"
      };
    case SLIDES.FRONT_VIEW.type:
      return {
        paddingBottom: "12vh",
        backgroundPosition: "center",
        backgroundSize: "60%",
        mask: `url(assets/mask.svg)`,
        maskSize: `${maskSize?.width}px ${maskSize?.height}px`,
        maskRepeat: "no-repeat",
        maskPosition: "center"
      };
    default:
      return defaultProps;
  }
};
