import SliderSVG from "assets/Layout/slider.svg";
import BottomBar from "components/menu/BottomBar";
import TopBar from "components/menu/TopBar";
import ToggledView from "components/ToggledView";
import { IViewModeProps } from "models/Layout";
import React from "react";
import { Outlet } from "react-router-dom";
import {
  StyledBottomBarContainer,
  StyledContentContainer,
  StyledLayoutContainer,
  StyledSliderContainer,
  StyledTopBarContainer
} from "./styles";

interface IProps {
  animationDirection: IViewModeProps["animationDirection"];
  iFrameMode: boolean;
  marginBottom: number;
  sliderWidth: number;
  sliderHeight: number;
  toggledView: boolean;
}

const Component = ({
  animationDirection,
  iFrameMode,
  sliderWidth,
  sliderHeight,
  marginBottom,
  toggledView
}: IProps) => (
  <StyledLayoutContainer toggledView={toggledView}>
    <StyledTopBarContainer className={iFrameMode ? "hide" : ""}>
      <TopBar />
    </StyledTopBarContainer>
    <StyledContentContainer>
      {toggledView ? <ToggledView /> : <Outlet />}
    </StyledContentContainer>
    <StyledSliderContainer
      className={
        animationDirection
          ? animationDirection === "out"
            ? "animationFromTopToBottom"
            : "animationFromBottomToTop"
          : "initial"
      }
    >
      <SliderSVG
        width={sliderWidth}
        height={sliderHeight}
        viewBox="0 0 1920 1080"
      />
    </StyledSliderContainer>
    <StyledBottomBarContainer
      IframeMarginBottom={marginBottom}
      className={iFrameMode ? "hide" : ""}
    >
      <BottomBar />
    </StyledBottomBarContainer>
  </StyledLayoutContainer>
);

export default Component;
