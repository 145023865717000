import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBrochureImagesProps, initialState } from "models/Brochure";

export const BrochureImagesSlice = createSlice({
  name: "brochureImages",
  initialState,
  reducers: {
    setBrochureImages: (
      state,
      action: PayloadAction<IBrochureImagesProps>
    ) => ({
      ...state,
      ...action.payload
    }),
    resetBrochureImages: () => initialState
  }
});

export const {
  setBrochureImages,
  resetBrochureImages
} = BrochureImagesSlice.actions;

export default BrochureImagesSlice.reducer;
