import { Box } from "@mui/material";
import { styled } from "@mui/system";
import bgImage from "assets/Home/start-page-bg.png";
import { IBGWithMaskProps } from "models/Home";

export const StyledBg = styled(Box)({
  position: "absolute",
  zIndex: 0,
  width: "100%",
  height: "100vh",
  backgroundImage: `url(${bgImage})`,
  backgroundSize: "100vw 100vh"
});

export const StyledBgWithMask = styled(Box)(
  ({ mousePosition }: IBGWithMaskProps) => ({
    position: "absolute",
    zIndex: 1,
    width: "100vw",
    height: "100vh",
    mask: `radial-gradient(circle at ${mousePosition.x}px ${mousePosition.y}px, transparent 8vw, #fff 16vw)`,
    backdropFilter: "blur(15px)"
  })
);

export const StyledContentBox = styled(Box)({
  position: "absolute",
  zIndex: 2,
  width: "100vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  bottom: "15vh",
  background: "transparent",
  span: {
    lineHeight: 1.2
  },
  button: {
    padding: "3.75vw",
    fontFamily: "Graphik",
    fontSize: "1vw",
    ":hover": {
      color: "#8812C5"
    },
    color: "white",
    backgroundColor: "#8812C5"
  }
});

export const StyledFooter = styled(Box)({
  position: "absolute",
  zIndex: 2,
  bottom: 0,
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  background: "transparent",
  div: {
    display: "flex",
    alignItems: "center",
    padding: "0.6vw",
    fontSize: "0.6vw",
    svg: { marginRight: "0.25vw", height: "1.25vw", width: "1.25vw" }
  }
});

export const StyledMainTextContainer = styled("span")({
  fontFamily: "GraphikBold",
  fontSize: "5vw",
  "-webkit-font-smoothing": "antialiased"
});

export const StyledSecondaryTextContainer = styled("span")({
  fontSize: "2vw",
  b: {
    color: "#8812C5",
    fontFamily: "GraphikBold"
  }
});

export const StyledVerticalLineContainer = styled(Box)({
  margin: "0.5vw",
  svg: { height: "7vh", width: 2 }
});

export const StyledDialogTextContainer = styled(Box)({
  padding: "4vw",
  fontSize: "0.8vw",
  b: {
    color: "#8812C5",
    fontFamily: "GraphikBold"
  }

});

export const StyledDialogHeaderContainer = styled("span")({
  fontFamily: "GraphikBold",
  fontSize: "2vw",
  "-webkit-font-smoothing": "antialiased"
});