import { Box } from "@mui/material";
import { styled } from "@mui/system";
import {
  IBrochureSlide,
  IBrochureSlideContainerProps,
  IBrochureSlideSideLabelProps
} from "models/Brochure";
import { getBrochureSlideBackgroundProps } from "utils/styles";

export const StyledSlideContainer = styled(Box)(
  ({ bgSrc, count, type, flipped, mask }: IBrochureSlideContainerProps) => ({
    position: "absolute",
    top: `${count * 100}vh`,
    height: "100vh",
    width: "100vw",
    backgroundImage: bgSrc ? `url(${bgSrc})` : "",
    backgroundRepeat: "no-repeat",
    ...(flipped && { transform: "scaleX(-1)" }),
    ...getBrochureSlideBackgroundProps(type, mask)
  })
);

export const StyledMainSlideContentWrapper = styled(Box)({
  position: "relative",
  width: "100vw",
  height: "100vh",
  transform: "scaleX(-1)"
});

export const StyledMainSlideContent = styled(Box)({
  paddingTop: "12vh",
  paddingLeft: "10vw",
  display: "flex",
  width: "100%",
  svg: { width: "50vw", height: "50vh" }
});

export const StyledMainSlideContentText = styled(Box)({
  color: "white",
  paddingTop: "8vh",
  ".title": {
    fontSize: "2vw",
    fontWeight: "300"
  },
  ".subtitle": {
    fontSize: "1.8vw",
    fontFamily: "GraphikBold"
  },
  ".body": {
    fontSize: "1vw",
    lineHeight: 1.2,
    paddingTop: "3vh",
    b: {
      fontFamily: "GraphikBold"
    }
  }
});

export const StyledSlideSideLabel = styled(Box)(
  ({ side, count }: IBrochureSlideSideLabelProps) => ({
    position: "absolute",
    top: `calc(${count * 100}vh + 10vh)`,
    [side]: "10vw",
    color: "white",
    ".title": {
      fontSize: "2vw",
      fontWeight: "400"
    },
    ".subtitle": {
      fontSize: "3vw",
      fontFamily: "GraphikBold"
    }
  })
);

export const StyledSlideCenterLabel = styled(Box)(
  ({ count }: IBrochureSlide) => ({
    position: "absolute",
    top: `${count * 100}vh`,
    left: "50%",
    transform: "translateX(-50%)",
    color: "white",
    textAlign: "center",
    ".title": {
      fontSize: "2.5vw",
      fontWeight: "300",
      b: { fontFamily: "GraphikBold" }
    },
    ".subtitle": {
      fontSize: "0.65vw"
    }
  })
);

export const StyledPropContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "baseline",
  span: { fontSize: "0.65vw" }
});

export const StyledPropContent = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "2.5vw",
  svg: { marginRight: "0.65vw" }
});

export const StyledSlideThumb = styled(Box)(
  ({ active }: { active: boolean }) => ({
    width: "1vw",
    height: active ? 3 : 1,
    opacity: active ? 1 : 0.5,
    backgroundColor: "white"
  })
);

export const StyledSwiperContainer = styled(Box)({
  paddingTop: "30vh",
  ".swiper": { transform: "scale(1.2)", paddingBottom: 50 }
});
