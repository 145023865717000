import { Box, Button } from "@mui/material";
import { styled } from "@mui/system";

export const StyledBookFormCloseButton = styled(Button)({
  minWidth: "fit-content",
  padding: 0,
  position: "absolute",
  top: "2vh",
  right: "1vw",
  svg: {
    width: "1.25vw",
    height: "1.25vw"
  }
});

export const StyledBookFormContainer = styled(Box)(
  ({ small }: { small: boolean }) => ({
    position: "relative",
    borderRadius: 8,
    width: "55vw",
    height: small ? " 50vh" : "65vh",
    marginBottom: "6vh",
    backgroundColor: "white",
    overflow: "hidden",
    display: "flex"
  })
);

export const StyledBookFormWrapper = styled(Box)({
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(255, 255, 255, 0.3)",
  backdropFilter: "blur(8px)"
});
