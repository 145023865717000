import { Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import { MenuBarProps, SummaryMenuItemProps } from "models/menu";

export const StyledBottomBarContainer = styled(Box)({
  width: "100vw",
  height: "10.75vh",
  position: "relative",
  ".whiteBarShape": {
    position: "absolute",
    bottom: 0
  },
  ".summaryBar": {
    position: "absolute",
    bottom: 0
  }
});

export const StyledMenuItem = styled(Button)(
  ({ xPos, yPos, radius, side }: SummaryMenuItemProps) => ({
    position: "absolute",
    bottom: yPos,
    [side]: xPos,
    width: radius,
    height: radius,
    borderRadius: "50%"
  })
);

export const StyledButtonsContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  button: {
    margin: "0 0.6vw",
    width: "2vw",
    minWidth: "2vw",
    height: "2vw",
    color: "#AA5FE4",
    borderRadius: "100%",
    "&:hover": { backgroundColor: "rgba(138,23,204,0.05)" }
  },
  position: "absolute",
  width: "23vw",
  left: "50%",
  transform: "translateX(-50%)",
  bottom: "3vw"
});

export const StyledPurpleButtonContainer = styled(Box)(
  ({ buttonMarginBottom, buttonHeight, buttonWidth }: MenuBarProps) => ({
    position: "absolute",
    width: "100%",
    "&.initial": { bottom: 0 },
    "&.animationIn": {
      bottom: "-300%",
      animation: "bottomBarSlideToTop 1s",
      "@keyframes bottomBarSlideToTop": {
        "0%": {
          bottom: 0,
          opacity: 1
        },
        "25%": {
          bottom: 0,
          opacity: 1
        },
        "50%": {
          bottom: "50%",
          opacity: 1
        },
        "75%": {
          bottom: "75%",
          opacity: 0
        },
        "100%": {
          bottom: "100%",
          opacity: 0
        }
      }
    },
    "&.animationOut": {
      bottom: 0,
      animation: "bottomBarSlideToBottom 2.5s",
      "@keyframes bottomBarSlideToBottom": {
        "0%": {
          bottom: "-100%",
          opacity: 0
        },
        "75%": {
          bottom: "-100%",
          opacity: 0
        },
        "100%": {
          bottom: 0,
          opacity: 1
        }
      }
    },
    button: {
      position: "absolute",
      bottom: buttonMarginBottom,
      left: "50%",
      fontFamily: "Graphik",
      transform: "translateX(-50%)",
      width: buttonWidth * 0.75,
      height: buttonHeight * 0.9,
      opacity: 0,
      zIndex: 10
    },
    ".barBtn": {
      width: buttonWidth,
      height: buttonHeight,
      position: "absolute",
      bottom: buttonMarginBottom! - 6,
      left: "50%",
      transform: "translateX(-50%)"
    }
  })
);
