import { ICarConfigProps, IUnrealCarConfig } from "../models/WebGLConfigurator";
import { IMAGE_CAR_COLORS, IMAGE_CAR_INTERIOR_COLORS, IMAGE_CAR_RIMS } from "../constants/images";

export async function publishModelState(state: ICarConfigProps): Promise<Response> {

  const simplifiedConfig: IUnrealCarConfig = {
    carpaint: state.carpaint?.urlValue || IMAGE_CAR_COLORS.YELLOW,
    rims: state.rims?.urlValue || IMAGE_CAR_RIMS.SPORTS_CAR_BASIS,
    interior: state.interior?.urlValue || IMAGE_CAR_INTERIOR_COLORS.RED_BLACK
  }

  return fetch(
    'https://ngc-api.dynamic-visual-content.com/model-state',
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json"
      }),
      body: JSON.stringify(simplifiedConfig),
      mode: "cors"
    }
  )
}
export async function fetchModelState(): Promise<IUnrealCarConfig | false> {
  const response = await fetch(
    'https://ngc-api.dynamic-visual-content.com/model-state',
    {
      method: "GET",
      mode: "cors"
    }
  )
  // no content
  if (response.status == 204) {
    return false
  }
  return await response.json() as IUnrealCarConfig
}

export async function clearModelState() {
  await fetch(
    'https://ngc-api.dynamic-visual-content.com/model-state',
    {
      method: "DELETE",
      mode: "cors"
    }
  )
}
