import { IMAGE_PAYLOAD_MODEL } from "models/api/images";
import { POST } from "./index";

export const getCarImageAPI = (data: IMAGE_PAYLOAD_MODEL) =>
  POST({
    data,
    headers: {
      accept: "application/json",
      "odb-body-cache-tag": "max-age=0"
    },
    url:
      "https://cyp-production-eu-west-1.dynamic-visual-content.com/v1/media/images"
  });

export const getCarVideoAPI = (data: IMAGE_PAYLOAD_MODEL) =>
  POST({
    data,
    headers: {
      accept: "application/json",
      "odb-body-cache-tag": "max-age=0"
    },
    url:
      "https://cyp-production-eu-west-1.dynamic-visual-content.com/v1/media/videos"
  });
