import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import Component from "./component";
import { getPreviewConfig } from "./config";

const PreviewScreen = () => {
  const [isBookFormOpen, setIsBookFormOpen] = useState(false);

  const {
    carpaintImageURL: carpaintImgSrc,
    rimsImageURL: rimsImgSrc,
    interiorImageURL: interiorImgSrc
  } = useSelector((state: RootState) => state.media);

  const config = getPreviewConfig({
    carpaintImgSrc,
    rimsImgSrc,
    interiorImgSrc
  });

  const handleBookFormOpenClose = () => setIsBookFormOpen(!isBookFormOpen);

  return (
    <Component
      isBookFormOpen={isBookFormOpen}
      onBookFormOpenClose={handleBookFormOpenClose}
      config={config}
    />
  );
};

export default PreviewScreen;
