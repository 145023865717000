import { Stack } from "@mui/material";
import ConfiguratorActive from "assets/Landing/ConfiguratorScreen/configuratorActive.svg";
import ConfiguratorConnector from "assets/Landing/ConfiguratorScreen/configuratorConnector.svg";
import ConfiguratorDisabled from "assets/Landing/ConfiguratorScreen/configuratorDisabled.svg";
import LineDown from "assets/Landing/ConfiguratorScreen/lineDown.svg";
import LineUp from "assets/Landing/ConfiguratorScreen/lineUp.svg";
import LineUpShort from "assets/Landing/ConfiguratorScreen/lineUpShort.svg";
import PreviewActive from "assets/Landing/ConfiguratorScreen/previewActive.svg";
import PreviewDisabled from "assets/Landing/ConfiguratorScreen/previewDisabled.svg";
import WelcomeActive from "assets/Landing/ConfiguratorScreen/welcomeActive.svg";
import WelcomeDisabled from "assets/Landing/ConfiguratorScreen/welcomeDisabled.svg";
import ConfigurationControls from "components/WebGLConfigurator/UserInterface";
import React, { useEffect, useState } from "react";
import {
  StyledConnector,
  StyledContent,
  StyledLabel,
  StyledStepperContainer
} from "./styles";

interface IProps {
  activeStep: number;
  carModel: any;
  loadCamera: any;
  loadCar: any;
  loadEnvironment: any;
  setActiveStep: (step: number) => void;
  stepper: {
    width: number;
    lineHeight: number;
    lineShortHeight: number;
    lineLongHeight: number;
  };
  useCurrentCarConfig: any;
}

const Component = ({
  activeStep,
  carModel,
  loadCamera,
  loadCar,
  loadEnvironment,
  setActiveStep,
  stepper,
  useCurrentCarConfig
}: IProps) => {
  const [IsDisplayed, setIsDisplayed] = useState({
    welcome: activeStep === 0,
    configurator: activeStep === 1,
    preview: activeStep === 2
  });

  useEffect(() => {
    setTimeout(() => {
      setIsDisplayed({
        welcome: activeStep === 0,
        configurator: activeStep === 1,
        preview: activeStep === 2
      });
    }, 1000);
  }, [activeStep]);

  return (
    <StyledStepperContainer
      className={activeStep === 1 ? "configMode" : "initial"}
    >
      <Stack spacing="1vw">
        <StyledLabel
          className={activeStep === 0 ? "active" : ""}
          onClick={() => setActiveStep(0)}
        >
          {activeStep === 0 ? <WelcomeActive /> : <WelcomeDisabled />}
        </StyledLabel>
        {IsDisplayed.welcome && (
          <StyledConnector
            lineHeight={stepper.lineHeight}
            className={activeStep === 0 ? "" : "close"}
          >
            <LineDown
              viewBox="0 0 16 81"
              width={stepper.width}
              height={stepper.lineHeight}
            />
          </StyledConnector>
        )}
        {IsDisplayed.configurator && (
          <StyledConnector
            lineHeight={stepper.lineShortHeight}
            className={activeStep === 1 ? "" : "close"}
          >
            <LineUpShort
              viewBox="0 0 16 26"
              width={stepper.width}
              height={stepper.lineShortHeight}
            />
          </StyledConnector>
        )}
        <StyledLabel
          className={activeStep === 1 ? "active" : ""}
          onClick={() => setActiveStep(1)}
        >
          {activeStep === 1 ? <ConfiguratorActive /> : <ConfiguratorDisabled />}
        </StyledLabel>
        {IsDisplayed.configurator && (
          <Stack direction="row">
            <StyledConnector
              lineHeight={stepper.lineLongHeight}
              className={activeStep === 1 ? "" : "close"}
            >
              <ConfiguratorConnector
                viewBox="0 0 16 392"
                width={stepper.width}
                height={stepper.lineLongHeight}
              />
            </StyledConnector>
            <StyledContent
              lineHeight={stepper.lineLongHeight}
              className={activeStep === 1 ? "" : "close"}
            >
              <ConfigurationControls
                {...{
                  carModel,
                  loadEnvironment,
                  loadCamera,
                  loadCar,
                  useCurrentCarConfig
                }}
              />
            </StyledContent>
          </Stack>
        )}
        {IsDisplayed.preview && (
          <StyledConnector
            lineHeight={stepper.lineHeight}
            className={activeStep === 2 ? "" : "close"}
          >
            <LineUp
              viewBox="0 0 16 82"
              width={stepper.width}
              height={stepper.lineHeight}
            />
          </StyledConnector>
        )}
        <StyledLabel
          className={activeStep === 2 ? "active" : ""}
          onClick={() => setActiveStep(2)}
        >
          {activeStep === 2 ? <PreviewActive /> : <PreviewDisabled />}
        </StyledLabel>
      </Stack>
    </StyledStepperContainer>
  );
};

export default Component;
