import {
  Box,
  Button,
  Checkbox,
  InputBase,
  InputLabel,
  Stack
} from "@mui/material";

import {
  bookFormExpItems,
  bookFormFields
} from "components/Landing/PreviewScreen/BookForm/config";
import { IBookFormProps } from "models/Landing";
import React from "react";

import {
  StyledBookFormHeader,
  StyledBookFormSubHeader,
  StyledConfirmationContainer,
  StyledExpContent,
  StyledExperienceSelectorContainer,
  StyledExpItem,
  StyledExpText,
  StyledFormContainer,
  StyledImageContainer,
  StyledInputContainer,
  StyledTermsContainer
} from "./styles";

interface IProps {
  checked: boolean;
  formValues: IBookFormProps;
  isFormValid: boolean;
  onAutoFill: () => void;
  onBook: () => void;
  onCheck: () => void;
  onFormValueChange: (value: { [x: string]: string }) => void;
}

const FormScreen = ({
  checked,
  formValues,
  isFormValid,
  onAutoFill,
  onBook,
  onCheck,
  onFormValueChange
}: IProps) => (
  <>
    <StyledImageContainer />
    <StyledFormContainer>
      <StyledBookFormHeader>Book</StyledBookFormHeader>
      <StyledBookFormSubHeader>
        Virtual sales appointment
      </StyledBookFormSubHeader>
      <Stack spacing={"1.5vh"}>
        {bookFormFields.map(row => (
          <Stack direction="row" spacing={"1vw"}>
            {row.items.map(field => (
              <StyledInputContainer>
                <InputLabel>{field.label}</InputLabel>
                <InputBase
                  onClick={onAutoFill}
                  placeholder={field.placeholder}
                  size="small"
                  // @ts-ignore
                  value={formValues[field.id]}
                  fullWidth
                  onChange={e =>
                    onFormValueChange({
                      [field.id]: e.target.value
                    })
                  }
                />
              </StyledInputContainer>
            ))}
          </Stack>
        ))}
        <StyledExperienceSelectorContainer>
          <StyledExpText>
            Select the experience that fit you better:
          </StyledExpText>
          <StyledExpContent>
            {bookFormExpItems.map(item => (
              <StyledExpItem
                onClick={() => {
                  onFormValueChange({ selectedItem: item.id });
                }}
                className={
                  formValues.selectedItem === item.id ? "selected" : ""
                }
              >
                {item.icon}
                <Box>{item.label}</Box>
              </StyledExpItem>
            ))}
          </StyledExpContent>
        </StyledExperienceSelectorContainer>
        <StyledTermsContainer>
          <b>Terms of Use:</b> The data entry in this form is only for the
          functional demonstration of our backend components. After completion
          of the journey, the information entered is deleted. It will not be
          stored.
        </StyledTermsContainer>
        <StyledConfirmationContainer>
          <Checkbox checked={checked} onChange={onCheck} />
          <Box>
            I have read this information and agree that my data may only be used
            for this demo and will be deleted immediately after use in the demo.
          </Box>
        </StyledConfirmationContainer>
        <Button disabled={!isFormValid} variant="contained" onClick={onBook}>
          Book an appointment
        </Button>
      </Stack>
    </StyledFormContainer>
  </>
);

export default FormScreen;
