import { MENU_ITEMS } from "constants/menuItems";
import { APP_ROUTES } from "constants/routing";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "store";
import { resetLandingStepper } from "store/reducers/LandingStepper";
import { resetViewMode, switchViewMode } from "store/reducers/ViewMode";
import { useSVGSizes } from "utils/resizeCustomHook";
import Component from "./component";
import SummaryBar from "./SummaryBar";

const BottomBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { bottomBar, toggleButton, summaryBar, summaryButtons } = useSVGSizes();

  const animationDirection = useSelector(
    (state: RootState) => state.viewMode.animationDirection
  );

  const currentMenuSvg = MENU_ITEMS.find(
    menuItem => menuItem.route === location.pathname
  )?.svg?.({ barHeight: bottomBar.height, barWidth: bottomBar.width });

  const selectedItemIndex = useMemo(
    () => MENU_ITEMS.findIndex(item => item.route === location.pathname),
    [location.pathname]
  );

  const isSummary = useMemo(() => location.pathname === APP_ROUTES.SUMMARY, [
    location
  ]);

  const handleGoPrev = () => {
    const prevItem = MENU_ITEMS[selectedItemIndex - 1];
    if (prevItem) {
      navigate(prevItem.route);
      if (prevItem.route === APP_ROUTES.HOME) dispatch(resetViewMode());
      if (prevItem.route === APP_ROUTES.ADVERT) dispatch(resetLandingStepper());
    }
  };

  const handleGoNext = () => {
    const nextItem = MENU_ITEMS[selectedItemIndex + 1];
    if (nextItem) {
      navigate(nextItem.route);
      if (nextItem.route === APP_ROUTES.APPOINTMENT)
        dispatch(resetLandingStepper());
    }
  };

  const handleOnNavigate = (route: string) => navigate(route);

  const handleSwitchViewMode = () => {
    dispatch(switchViewMode());
  };

  return isSummary ? (
    <SummaryBar
      barHeight={summaryBar.height}
      barWidth={summaryBar.width}
      onNavigate={handleOnNavigate}
      summaryButtons={summaryButtons}
    />
  ) : (
    <Component
      animationDirection={animationDirection}
      arrowsHeight={bottomBar.arrows.height}
      arrowsWidth={bottomBar.arrows.width}
      buttonHeight={toggleButton.height}
      buttonMarginBottom={toggleButton.marginBottom}
      buttonWidth={toggleButton.width}
      currentMenuSvg={currentMenuSvg}
      goNext={handleGoNext}
      goPrev={handleGoPrev}
      onSwitchViewMode={handleSwitchViewMode}
    />
  );
};

export default BottomBar;
