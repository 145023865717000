import axios from "axios";

interface APIProps {
  data: any;
  url: string;
  headers: { [key: string]: string };
}

export const POST = ({ data, url, headers }: APIProps) =>
  axios({
    method: "post",
    url,
    data,
    headers
  });
