import { Box, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledTextContainer = styled(Box)({
  fontSize: "2.5vw",
  fontWeight: "800",
  fontFamily: "GraphikBold"
});

export const StyledThankYouScreenContainer = styled(Stack)({
  flex: 1,
  alignItems: "center",
  svg: {
    marginTop: "5vh",
    width: "6vw",
    height: "6vw"
  },
  button: {
    fontSize: "0.9vw",
    fontWeight: "bold",
    borderRadius: 32,
    width: "fit-content",
    textTransform: "none"
  },
  ".smallText": {
    lineHeight: 2
  }
});
