import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { IScrollingScreenContainerProps } from "models/Landing";

export const StyledScreensContainer = styled(Box)({
  position: "relative",
  width: "100vw",
  height: "100vh"
});

export const StyledScrollingScreenContainer = styled(Box)(
  ({ counter }: IScrollingScreenContainerProps) => ({
    position: "absolute",
    top: 0,
    transform: `translateY(${-counter * 100}vh)`,
    transition: "transform 1s linear"
  })
);
