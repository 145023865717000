import { Box } from "@mui/material";
import { styled } from "@mui/system";
import bgImagePNG from "assets/Landing/BookForm/form_image_vr.png";

export const StyledBookFormHeader = styled(Box)({
  fontSize: "2.5vw",
  fontWeight: "800",
  fontFamily: "GraphikBold"
});

export const StyledBookFormSubHeader = styled(Box)({
  fontSize: "0.7vw",
  marginBottom: "1.5vh"
});

export const StyledConfirmationContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  fontSize: "0.6vw"
});

export const StyledExpContent = styled(Box)({
  flex: 4,
  display: "flex"
});

export const StyledExperienceSelectorContainer = styled(Box)({
  display: "flex"
});

export const StyledExpItem = styled(Box)({
  width: "2.5vw",
  height: "2.5vw",
  backgroundColor: "rgba(196, 196, 196, 0.32)",
  padding: "0.4vw",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  fontSize: "0.6vw",
  border: "2px solid transparent",
  borderRadius: 8,
  "&:nth-child(2)": {
    margin: "0 1vw"
  },
  "&:hover, &.selected": {
    borderColor: "#7C27BE",
    color: "#7C27BE",
    img: {
      filter:
        "invert(100%) sepia(85%) saturate(6000%) hue-rotate(279deg) brightness(70%) contrast(100%)"
    }
  }
});

export const StyledExpText = styled(Box)({
  fontSize: "0.8vw",
  flex: 2
});

export const StyledFormContainer = styled(Box)({
  flex: 2,
  padding: "1vw 5vh",
  button: {
    borderRadius: 16,
    width: "fit-content",
    marginLeft: "auto!important",
    textTransform: "none"
  }
});

export const StyledImageContainer = styled(Box)({
  backgroundImage: `url(${bgImagePNG})`,
  backgroundSize: "100% 100%",
  flex: 1.5
});

export const StyledInputContainer = styled(Box)({
  flex: 1,
  position: "relative",
  label: {
    fontFamily: "Graphik",
    fontSize: "0.6vw",
    color: "#848282"
  },
  input: {
    padding: "0.5vw!important",
    backgroundColor: "rgba(196, 196, 196, 0.32)",
    border: "2px solid transparent",
    borderRadius: 6,
    fontSize: "0.8vw",
    fontFamily: "GraphikBold",
    fontWeight: "bold",
    "&:focus, &:hover": {
      borderColor: "#7C27BE"
    }
  }
});

export const StyledTermsContainer = styled(Box)({
  fontSize: "0.5vw"
});
