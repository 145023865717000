import { Box, Stack } from "@mui/material";
import { styled } from "@mui/system";
import bg_surreal from "assets/Appointment/bg_surreal.png";

export const StyledAppointmentButtonContainer = styled(Stack)({
  justifyContent: "center",
  marginTop: "3vh",
  button: {
    fontFamily: "GraphikBold"
  }
});

export const StyledAppointmentContainer = styled(Box)({
  width: "100vw",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundImage: `url(${bg_surreal})`,
  backgroundPosition: "center",
  backgroundSize: "105vw 105vh",
  iframe: {
    width: "100vw",
    height: "100vh",
    border: "none",
    display: "none",
    "&.active": {
      display: "flex"
    }
  }
});

export const StyledAppointmentContainerOverlay = styled(Box)({
  display: "block",
  width: "100%",
  height: "100%",
  position: "fixed",
  left: "0",
  top: "0",
  zIndex: "1",
  backgroundColor: "rgba(0,0,0,0.4)"
});

export const StyledAppointmentContent = styled(Stack)({
  flex: 1,
  zIndex: "2",
  justifyContent: "center",
  textAlign: "center",
  button: {
    width: "fit-content",
    padding: "1.75vh 1.75vw",
    backgroundColor: "white",
    border: "1px solid transparent",
    borderRadius: "3.5vh",
    fontWeight: "bold",
    color: "black",
    textTransform: "none",
    "&:hover": {
      color: "white"
    }
  },
  h1: {
    margin: 0,
    color: "white",
    textShadow: "0 0 10px rgba(0,0,0,0.5)",
    "&.mainHeader": {
      fontSize: "3vw",
      fontWeight: "400"
    },
    "&.secondaryHeader": {
      fontSize: "5vw",
      fontWeight: "800"
    },
  },
  h3: {
    margin: 0,
    color: "white",
    textShadow: "0 0 10px rgba(0,0,0,0.2)",
    fontSize: "2vw",
    fontWeight: "800"
  }
});
