export interface IViewModeProps {
  readonly animationDirection: string;
  readonly iFrameMode: boolean;
  readonly toggledView: boolean;
}

export const initialState: IViewModeProps = {
  animationDirection: "",
  iFrameMode: false,
  toggledView: false
};

export interface ILayoutContainerProps {
  toggledView: boolean;
}
