import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { MenuBarProps, TopBarProps } from "models/menu";

export const StyledTopBarContainer = styled(Box)(
  ({ toggled }: TopBarProps) => ({
    position: "relative",
    width: "100vw",
    height: "10.75vh",
    ".barShape": {
      position: "absolute",
      top: toggled ? "7%" : "-100%",
      transition: "2s all"
    }
  })
);

export const StyledWhiteBarContainer = styled(Box)({
  position: "absolute",
  top: 0
});

export const StyledHeaderTextContainer = styled(Box)({
  position: "absolute",
  top: "calc(50% - 2vh)",
  left: "50%",
  transform: "translate(-50%,-50%)",
  display: "flex",
  alignItems: "center",
  img: {
    width: "2vw",
    height: "2vw",
    marginRight: "2vw"
  },
  ".bold-text": {
    fontFamily: "GraphikBold",
    fontSize: "1.5vw",
    fontWeight: "900"
  },
  ".regular-text": {
    fontSize: "1.5vw",
    fontWeight: "100"
  },
  ".test-layer": {
    backgroundColor: "white",
    height: 100
  }
});

export const StyledPurpleButtonContainer = styled(Box)(
  ({ barHeight, buttonHeight, buttonWidth }: MenuBarProps) => ({
    position: "absolute",
    width: "100%",
    "&.initial": { top: "-100%" },
    "&.animationIn": {
      top: "-100%",
      animation: "topBarSlideToBottom 1s",
      "@keyframes topBarSlideToBottom": {
        "0%": {
          top: 5,
          opacity: 1
        },
        "25%": {
          top: 5,
          opacity: 1
        },
        "50%": {
          top: "50%",
          opacity: 1
        },
        "100%": {
          top: "200%",
          opacity: 0
        }
      }
    },
    "&.animationOut": {
      top: 5,
      animation: "topBarSlideToTop 2.5s",
      "@keyframes topBarSlideToTop": {
        "0%": {
          top: "-100%",
          opacity: 0
        },
        "50%": {
          top: "-100%",
          opacity: 0
        },
        "100%": {
          top: 5,
          opacity: 1
        }
      }
    },
    button: {
      position: "absolute",
      top: barHeight! - 5,
      left: "50%",
      transform: "translateX(-50%)",
      padding: 0,
      width: buttonWidth * 0.7,
      height: buttonHeight * 0.9,
      opacity: 0,
      zIndex: 11
    },
    ".barBtn": {
      width: buttonWidth,
      height: buttonHeight,
      position: "absolute",
      top: barHeight! - 11,
      left: "50%",
      transform: "translateX(-50%)"
    }
  })
);
