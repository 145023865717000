import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const StyledBrochureContainer = styled(Box)({
  position: "relative",
  overflow: "scroll",
  overflowX: "hidden",
  width: "100vw",
  height: "100vh",
  background: "black",
  "::-webkit-scrollbar": {
    width: "0.5vw"
  },
  "::-webkit-scrollbar-track": {
    background: "rgba(255, 255, 255, 0.18)"
  },
  "::-webkit-scrollbar-thumb": {
    background: "white",
    borderRadius: 50
  }
});

export const StyledPurchaseButtonContainer = styled(Box)(
  ({
    purchaseBtn
  }: {
    purchaseBtn: { width: number; height: number; xPos: number; yPos: number };
  }) => ({
    position: "fixed",
    top: "70vh",
    right: "1vw",
    zIndex: 1,
    button: {
      position: "absolute",
      top: purchaseBtn.yPos,
      transform: "translateY(-50%)",
      right: purchaseBtn.xPos,
      height: purchaseBtn.height,
      borderRadius: 52,
      width: purchaseBtn.width
    }
  })
);
