export interface IMediaProps {
  readonly advertImageURL: string;
  readonly landingImageURL: string;
  readonly emailImageURL: string;
  readonly carpaintImageURL: string;
  readonly rimsImageURL: string;
  readonly interiorImageURL: string;
  readonly videoURL?: string;
}

export const initialState: IMediaProps = {
  advertImageURL: "",
  landingImageURL: "",
  emailImageURL: "",
  carpaintImageURL: "",
  rimsImageURL: "",
  interiorImageURL: "",
  videoURL: ""
};
