import { Box, Button, Chip } from "@mui/material";
import OfferLayerSVG from "assets/Email/offer.svg";
import { IBookFormProps } from "models/Landing";
import { IMediaProps } from "models/Media";
import React from "react";
import { emailMenuItems, emails } from "./config";
import {
  StyledEmailBadge,
  StyledEmailColumn,
  StyledEmailContainer,
  StyledEmailItem,
  StyledEmailItemAvatar,
  StyledImageContainer,
  StyledLetterHeader,
  StyledLetterImageContainer,
  StyledMenuItem,
  StyledMenuItemList,
  StyledRoundedTitle
} from "./styles";

interface IProps {
  imgSrc: IMediaProps["emailImageURL"];
  name: IBookFormProps["name"];
  offerLayerHeight: number;
  offerLayerWidth: number;
  onNavigate: () => void;
}

const Component = ({
  imgSrc,
  name,
  offerLayerHeight,
  offerLayerWidth,
  onNavigate
}: IProps) => (
  <StyledEmailContainer>
    <StyledEmailColumn flex={0.4}>
      <StyledRoundedTitle>New Email</StyledRoundedTitle>
      <StyledMenuItemList spacing={"3vh"}>
        {emailMenuItems.map(item =>
          item.isHeader ? (
            <h2>{item.title}</h2>
          ) : (
            <StyledMenuItem selected={item.selected}>
              {item.icon}
              {item.title}
              {item.label && <Chip className="label" label={item.label} />}
            </StyledMenuItem>
          )
        )}
      </StyledMenuItemList>
    </StyledEmailColumn>
    <StyledEmailColumn flex={0.7} withBg withMargin>
      {emails.map(item => (
        <StyledEmailItem selected={item.selected}>
          <StyledEmailItemAvatar
            large
            src={item.selected ? imgSrc : item.avatarSrc}
            alt="avatar"
          />
          <Box className="emailContent">
            <StyledEmailBadge>{item.time}</StyledEmailBadge>
            <h3>{item.subject}</h3>
            <Box className="body">
              {item.body.map(bodyString => (
                <Box>{bodyString}</Box>
              ))}
            </Box>
          </Box>
        </StyledEmailItem>
      ))}
    </StyledEmailColumn>
    <StyledEmailColumn withBg flex={1}>
      <StyledLetterHeader>
        <Box>
          <StyledEmailItemAvatar src={imgSrc} alt="header" />
          Cars for you
          <span>Today at 12:10 pm</span>
        </Box>
        <h2>Hey {name}, your Super GT 900 is waiting for you!</h2>
      </StyledLetterHeader>
      <StyledLetterImageContainer spacing={2}>
        <StyledImageContainer>
          <Box className="overlayBlock">
            <OfferLayerSVG
              viewBox="0 0 250 350"
              height={offerLayerHeight}
              width={offerLayerWidth}
            />
          </Box>
          <Box className="imageBlock">
            <img src={imgSrc} width={"100%"} height={"100%"} />
          </Box>
        </StyledImageContainer>
        <Box>
          The iconic super car GT 900, unites beauty and performance to create
          an incredible driving experience. Access your digital brochure:
        </Box>
        <Button onClick={onNavigate} variant="contained">
          Your Digital Brochure
        </Button>
      </StyledLetterImageContainer>
    </StyledEmailColumn>
  </StyledEmailContainer>
);

export default Component;
