export const SLIDER_INITIAL_SIZES = {
  WIDTH: 1920,
  HEIGHT: 1080,
  HEIGHT_RATIO: 1920 / 1080
};

export const BOTTOM_BAR_INITIAL_SIZES = {
  WIDTH: 1920,
  HEIGHT: 200,
  HEIGHT_WITHOUT_SHADOW: 160,
  HEIGHT_RATIO: 1920 / 200,
  BUTTON_MARGIN_BOTTOM: 1920 / 160,
  ARROWS: {
    WIDTH: 18,
    HEIGHT: 28,
    WIDTH_RATIO: 1920 / 18,
    HEIGHT_RATIO: 1920 / 28
  }
};

export const TOP_BAR_INITIAL_SIZES = {
  WIDTH: 1920,
  HEIGHT: 100,
  HEIGHT_RATIO: 1920 / 100
};

export const SUMMARY_BAR_INITIAL_SIZES = {
  WIDTH: 1920,
  HEIGHT: 352,
  MARGIN: 156,
  HEIGHT_RATIO: 1920 / 352,
  MARGIN_RATIO: 1920 / 156
};

export const BUTTON_INITIAL_SIZES = {
  WIDTH: 534,
  HEIGHT: 77,
  HEIGHT_RATIO: 534 / 77,
  WIDTH_RATIO: 1920 / 534
};

export const SUMMARY_BUTTONS_INITIAL_SIZES = {
  RADIUS: 142,
  HEIGHT: 352,
  RADIUS_RATIO: 1920 / 142,
  HEIGHT_RATIO: 1920 / 352,
  EDGE_BUTTONS: {
    X_POS: 89,
    Y_POS: 160,
    X_POS_RATIO: 1920 / 89,
    Y_POS_RATIO: 1920 / 160
  },
  MIDDLE_BUTTONS: {
    X_POS: 409,
    Y_POS: 190,
    X_POS_RATIO: 1920 / 409,
    Y_POS_RATIO: 1920 / 190
  },
  CENTER_BUTTONS: {
    X_POS: 729,
    Y_POS: 205,
    X_POS_RATIO: 1920 / 729,
    Y_POS_RATIO: 1920 / 205
  }
};

export const OFFER_LAYER_INITIAL_SIZES = {
  WIDTH: 250,
  HEIGHT: 350,
  WIDTH_RATIO: 1920 / 250,
  HEIGHT_RATIO: 1920 / 350
};

export const PURCHASE_BUTTON_CONTAINER_INITIAL_SIZES = {
  WIDTH: 538,
  HEIGHT: 134,
  WIDTH_RATIO: 1920 / 538,
  HEIGHT_RATIO: 1920 / 134
};

export const PURCHASE_BUTTON_INITIAL_SIZES = {
  WIDTH: 156,
  HEIGHT: 72,
  X_POS: 131,
  Y_POS: 56,
  WIDTH_RATIO: 1920 / 156,
  HEIGHT_RATIO: 1920 / 72,
  X_POS_RATIO: 1920 / 131,
  Y_POS_RATIO: 1920 / 56
};

export const FRONT_VIEW_MASK_SIZES = {
  WIDTH: 958,
  HEIGHT: 634,
  WIDTH_RATIO: 1920 / 958,
  HEIGHT_RATIO: 1920 / 634
};

export const STEPPER_INITIAL_SIZE = {
  WIDTH: 20,
  LINE_HEIGHT: 80,
  LINE_SHORT_HEIGHT: 24,
  LINE_LONG_HEIGHT: 390,
  WIDTH_RATIO: 1920 / 20,
  LINE_HEIGHT_RATIO: 1920 / 80,
  LINE_SHORT_HEIGHT_RATIO: 1920 / 24,
  LINE_LONG_HEIGHT_RATIO: 1920 / 390
};
