import CheckIcon from "@mui/icons-material/Check";
import React from "react";
import {
  StyledBadgeContainer,
  StyledMenuContainer,
  StyledMenuItem
} from "./styles";

interface IProps {
  currSelectedItem: { id: string };
  availableOptions: any[];
  onClick: (item: any) => void;
}

const Component = ({ currSelectedItem, availableOptions, onClick }: IProps) => (
  <StyledMenuContainer>
    {availableOptions.map((item: any) => (
      <StyledMenuItem
        className={currSelectedItem.id === item.id ? "selected" : ""}
        onClick={() => {
          onClick(item);
        }}
        key={item.id}
      >
        <img src={item.iconPath} alt={item.label} />
        {currSelectedItem.id === item.id && (
          <StyledBadgeContainer>
            <CheckIcon />
          </StyledBadgeContainer>
        )}
      </StyledMenuItem>
    ))}
  </StyledMenuContainer>
);

export default Component;
