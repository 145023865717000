import { Box, CircularProgress } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  StyledSummaryContainer,
  StyledSummaryInfoContainer,
  StyledSummaryInfoHeader
} from "./styles";
import { getVideoByConfig } from "./utils";

const Summary = () => {
  const carConfig = useSelector(
    (state: RootState) => state.webGLConfigurator.carConfig
  );
  const videoSrc = useMemo(() => getVideoByConfig(carConfig), [carConfig]);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  return (
    <StyledSummaryContainer>
      <video autoPlay muted loop onPlay={() => setIsVideoLoading(false)}>
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      {isVideoLoading && (
        <Box className="loader">
          <CircularProgress color="secondary" size={"6vw"} />
        </Box>
      )}
      <StyledSummaryInfoContainer>
        <StyledSummaryInfoHeader>
          <h1 className="mainHeader">THIS IS</h1>
          <h1>NEXT-GEN</h1>
          <h1>CONTENT</h1>
          <h1 className="mainHeader">POWERED</h1>
          <h1 className="mainHeader">BY SYNOPS</h1>
        </StyledSummaryInfoHeader>
      </StyledSummaryInfoContainer>
    </StyledSummaryContainer>
  );
};

export default Summary;
