import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setActiveStep } from "store/reducers/LandingStepper";
import Component from "./component";

const Landing = () => {
  const dispatch = useDispatch();
  const activeStep = useSelector(
    (state: RootState) => state.landingStepper.activeStep
  );

  const carConfig = useSelector(
    (state: RootState) => state.webGLConfigurator.carConfig
  );

  const handleChangeActiveStep = (step: number) =>
    dispatch(setActiveStep(step));

  return (
    <Component
      activeStep={activeStep}
      carConfig={carConfig}
      onChangeActiveStep={handleChangeActiveStep}
    />
  );
};

export default Landing;
