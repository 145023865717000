import { Box } from "@mui/material";
import { SLIDES } from "constants/brochure";
import { IBrochureImagesProps } from "models/Brochure";
import { ICarConfigProps } from "models/WebGLConfigurator";
import React from "react";
import { StyledSlideContainer, StyledSlideSideLabel } from "./styles";

interface IProps {
  bgSrc: IBrochureImagesProps["interiorImageURL"];
  interior: ICarConfigProps["interior"];
}

const InteriorSlide = ({ bgSrc, interior }: IProps) => (
  <>
    <StyledSlideContainer
      bgSrc={bgSrc}
      count={SLIDES.INTERIOR.count}
      type={SLIDES.INTERIOR.type}
    />
    <StyledSlideSideLabel side="right" count={SLIDES.INTERIOR.count}>
      <Box className="title">Interior</Box>
      <Box className="subtitle">{interior?.label}</Box>
      <Box>{interior?.subLabel}</Box>
    </StyledSlideSideLabel>
  </>
);

export default InteriorSlide;
