import { Stack } from "@mui/material";
import { IArticleProps } from "models/Advert";
import React from "react";
import {
  StyledArticleCategory,
  StyledArticleFooter,
  StyledArticleTitle,
  StyledImageArticle
} from "./styles";

interface IProps {
  article: IArticleProps;
}

const ShortArticle = ({ article }: IProps) => (
  <StyledImageArticle>
    {article.img && (
      <img
        src={article.img.src}
        alt={article.img.alt}
        width={article.img.width}
      />
    )}
    <Stack>
      <StyledArticleCategory>{article.category}</StyledArticleCategory>
      <StyledArticleTitle>{article.title}</StyledArticleTitle>
      <StyledArticleFooter className="imageBlock">
        {article.footer}
      </StyledArticleFooter>
    </Stack>
  </StyledImageArticle>
);

export default ShortArticle;
