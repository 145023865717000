import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import Component from "./component";
import { getToggledViewConfig } from "./config";

const ToggledView = () => {
  const type = useSelector((state: RootState) => state.toggledView.type);

  const {
    body,
    subTitle,
    title,
    imgSrc,
    videoSrc,
    actionComponent
  } = getToggledViewConfig(type);
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const handleOnOpenCloseVideo = () => setIsVideoOpen(!isVideoOpen);

  return (
    <Component
      actionComponent={actionComponent}
      body={body}
      subTitle={subTitle}
      title={title}
      imgSrc={imgSrc}
      videoSrc={videoSrc}
      isVideoOpen={isVideoOpen}
      onOpenCloseVideo={handleOnOpenCloseVideo}
    />
  );
};

export default ToggledView;
