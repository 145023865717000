export enum IMAGE_BACKGROUND_IDS {
  SUNSET = "Sunset",
  HANGAR = "Hangar",
  DARK = "Dark",
  WHITE_ROOM = "Whiteroom"
}

export enum IMAGE_PERSPECTIVE_IDS {
  FRONT = "Still_01_front",
  SIDE_LEFT = "Still_02_side_left",
  SIDE_RIGHT = "Still_03_side_right",
  REAR = "Still_04_rear",
  REAR_LEFT = "Still_05_3_4_rear_left",
  REAR_RIGHT = "Still_06_3_4_rear_right",
  TOP = "Still_07_top",
  FRONT_LEFT = "Still_08_3_4_front_left",
  FRONT_RIGHT = "Still_09_3_4_front_right",
  DETAIL_1 = "Still_10_detail",
  DETAIL_2 = "Still_11_detail",
  DETAIL_3 = "Still_12_detail",
  INTERIOR = "Still_13_INT_seats"
}

export enum IMAGE_RESOLUTIONS {
  NHD = "nHD", // 640 x 360
  HDTV = "HDTV", // 1280 x 720
  FULL_HD = "FullHD", // 1920 x 1080
  _2K = "2K", // 2048 x 1152
  _4K = "4K", // 3840 x 2160
  SOCIAL = "social", // 1200 x 630
  INSTA = "insta", // 1080 x 1080
  MOBILE = "mobile", // 1440 x 2560
  TABLET = "tablet" // 1536 x 2048
}

export enum IMAGE_VARIANT_SETS {
  COLOR = "mat_Carpaint",
  RIM = "Switch_Rims",
  INTERIOR = "mat_int_leather"
}

export enum IMAGE_CAR_COLORS {
  YELLOW = "yellow", // Cyber Yellow
  RED = "red", // Imperial Red
  BLACK = "black", // Vampire Black
  BLUE_METAL = "blue_metal", // Pacific Blue Metallic
  GREEN_METAL = "green_metal", // Acid Green Metallic
  GREY_METAL = "grey_metal" // Silver Grey Metallic
}

export enum IMAGE_CAR_RIMS {
  SPORTS_CAR_BASIS = "SportsCar_Basis", // Ninja
  SEDAN_RIM_MV_TIRE = "Sedan_Rim_MV_Tire" // Shuriken
  // SEDAN = "Sedan", //	Burning Shuriken
  // SUV_RIM_MV_TIRE = "SUV_Rim_MV_Tire", // Razor
  // CAD_COLOR = "CAD_color", // Kunai
}

export enum IMAGE_CAR_INTERIOR_COLORS {
  RED_BLACK = "Red_Black", // Devil Red
  BLUE_BLACK = "Blue_Black", // Midnight Blue
  GREY_BLACK = "Grey_Black" // Iron Grey
  // WHITE = "white", // Ash Grey
}

export enum IMAGE_FILE_TYPE {
  JPG = "jpg",
  PNG = "png",
  MP4 = "mp4"
}

export enum IMAGE_QUALITY {
  HIGH = "High",
  MEDIUM = "Medium",
  LOW = "Low"
}

export enum IMAGE_QUEUE {
  FAST = "fast", // interactive
  SLOW = "slow" // content download
}
