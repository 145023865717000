import { APP_ROUTES } from "constants/routing";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { useSVGSizes } from "utils/resizeCustomHook";
import Component from "./component";
import { convertConfig, setCarOptionConfig } from "../../store/reducers/WebGLConfigurator";
import { clearModelState, fetchModelState } from "../../api/unreal-showroom";

const Email = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    fetchModelState().then(model => {
      if (model == false) {
        return;
      }
      clearModelState().catch((e) => console.error("error clearing persisted model state", e));
      const config = convertConfig(model);
      dispatch(setCarOptionConfig(config));
    })
  });

  const navigate = useNavigate();
  const { offerLayer } = useSVGSizes();
  const imgUrl = useSelector((state: RootState) => state.media.emailImageURL);
  const name = useSelector((state: RootState) => state.bookForm.name) || "Jane";

  const handleOnNavigate = () => navigate(APP_ROUTES.BROCHURE);

  return (
    <Component
      imgSrc={imgUrl}
      name={name}
      offerLayerHeight={offerLayer.height}
      offerLayerWidth={offerLayer.width}
      onNavigate={handleOnNavigate}
    />
  );
};

export default Email;
