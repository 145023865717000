import {
  IMAGE_BACKGROUND_IDS,
  IMAGE_PERSPECTIVE_IDS,
  IMAGE_RESOLUTIONS,
  IMAGE_VARIANT_SETS
} from "constants/images";

export const getRandomValue = (items: any[]) =>
  items[Math.floor(Math.random() * items.length)];

export const getCarImageUrl = (config: any) => ({
  product_id: "FutureCarSport",
  tracking_id: "TestScene1",
  background_id: IMAGE_BACKGROUND_IDS.WHITE_ROOM,
  perspective_id: IMAGE_PERSPECTIVE_IDS.FRONT_LEFT,
  configurations: [
    {
      entityName: "FutureCarSport",
      type: "MVEntityConfigurationVariants",
      configuration: {
        variantsToSwitch: [
          {
            variantSet: IMAGE_VARIANT_SETS.COLOR,
            variant: config.carpaint.urlValue
          },
          {
            variantSet: IMAGE_VARIANT_SETS.RIM,
            variant: config.rims.urlValue
          },
          {
            variantSet: IMAGE_VARIANT_SETS.INTERIOR,
            variant: config.interior.urlValue
          }
        ]
      }
    }
  ],
  resolution: IMAGE_RESOLUTIONS.INSTA,
  file_type: "jpg",
  output_quality: "High",
  target_queue: "fast"
});
