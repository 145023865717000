import PowerIconSVG from "assets/Brochure/power_icon.svg";
import SecondsIconSVG from "assets/Brochure/seconds_icon.svg";
import SpeedIconSVG from "assets/Brochure/speed_icon.svg";
import { IBrochureCarPropertiesProps } from "models/Brochure";
import React from "react";

export const carProperties: IBrochureCarPropertiesProps[] = [
  {
    title: "Top speed",
    icon: <SpeedIconSVG viewBox="0 0 48 48" width={"2.5vw"} height={"2.5vw"} />,
    value: 208,
    measure: "mph"
  },
  {
    title: "Power",
    icon: <PowerIconSVG viewBox="0 0 58 48" width={"3vw"} height={"2.5vw"} />,
    value: 630,
    measure: "bph"
  },
  {
    title: "0 - 62 mph",
    icon: (
      <SecondsIconSVG viewBox="0 0 48 49" width={"2.5vw"} height={"2.5vw"} />
    ),
    value: 3.7,
    measure: "s"
  }
];
