import img1 from "assets/Advert/digital_ad_photo1.png";
import img2 from "assets/Advert/digital_ad_photo2.png";
import img3 from "assets/Advert/digital_ad_photo3.png";
import { AdvertNavBarModel, IArticleProps } from "models/Advert";
import { IIterableItems } from "models/common";

export const advertColumns: IIterableItems<IArticleProps>[] = [
  {
    items: [
      {
        img: { alt: "digital ad left", src: img1, width: "100%" },
        title: "AI: Change what's possible",
        body:
        "The most advanced companies understand that while cloud sets you up with next-level " +
        "computing power and access to new kinds of data in the right quantity and quality, " +
        "AI is the bridge to convert that data into business value.",
        footer: "By Anna Mercury - 25 minutes ago"
      },
      {
        title: "Digital Fix - Fix Digital",
        body:
          "The controversy surrounding Cambridge Analytica highlights some of the most pressing issues of the digital " +
          "industry. At its core, the problem is the distribution of roles between humans and technology.",
        footer: "By Anna Mercury - 48 minutes ago"
      }
    ]
  },
  {
    items: [
      {
        isShortArticle: true,
        img: { alt: "rapid research", src: img2, width: "100%" },
        category: "TECH",
        title: "Rapid research",
        footer: "By Anna Mercury - 48 minutes ago"
      },
      {
        isShortArticle: true,
        img: { alt: "it's time to focus", src: img3, width: "100%" },
        category: "TECH",
        title: "It's time to Focus",
        footer: "By Anna Mercury - 2 hours ago"
      },
      {
        category: "TECH",
        title: "Study on women's financial realities",
        body:
          "Women are not the winners in the money game. On the contrary, their financial reality is still anything " +
          "but fair and balanced in today's society. Accenture set out to change exactly this and " +
          "to make a meaningful impact on fighting financial inequalities.",
        footer: "By Anna Mercury - 55 minutes ago"
      }
    ]
  },
  {
    items: [
      {
        isDynamicImage: true
      },
      {
        title: "Most Viewed",
        subTitle: "AI for business: Change what's possible",
        body:
          "Navigating the complexity of customer and business demands is no small feat. " + 
          "For organizations to stay relevant for tomorrow, they must adapt to the ever-evolving " + 
          "needs of customers while accelerating real growth through change.",
        footer: "By Anna Mercury - 4 hours ago"
      }
    ]
  }
];

export const advertNavigationTitles: AdvertNavBarModel = [
  "News",
  "Sports",
  "Entertainment",
  "Life",
  "Money",
  "Tech"
];
