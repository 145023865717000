import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const StyledConfiguratorWrapper = styled(Box)({
  width: "100vw",
  height: "100vh",
  position: "relative"
});

export const StyledConfiguratorContainer = styled(Box)({
  width: "100vw",
  height: "100vh"
});

export const StyledLoaderContainer = styled(Box)({
  position: "absolute",
  width: "100vw",
  height: "100vh",
  top: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#EBEBEB"
});
