import { getRandomValue } from "api/utils";
import { ICarConfigProps } from "models/WebGLConfigurator";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import AppRoutes from "routing/AppRoutes";
import { setCarOptionConfig } from "store/reducers/WebGLConfigurator";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const mainConfig = require("./config/mackevision-webgl-configurator.json");
    const carConfigs = mainConfig.webGL.carModels[0].carConfigs;
    const carpaint = carConfigs.filter(
      (item: { configProperty: string }) => item.configProperty === "carpaint"
    );
    const rims = carConfigs.filter(
      (item: { configProperty: string }) => item.configProperty === "rims"
    );
    const interior = carConfigs.filter(
      (item: { configProperty: string }) => item.configProperty === "interior"
    );
    const randomCarConfigs: ICarConfigProps = {
      carpaint: getRandomValue(carpaint),
      rims: getRandomValue(rims),
      interior: getRandomValue(interior)
    };
    dispatch(setCarOptionConfig(randomCarConfigs));
  }, []);

  return <AppRoutes />;
};
export default App;
