import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box } from "@mui/material";
import { IMediaProps } from "models/Media";
import React from "react";
import { carProps } from "./config";
import {
  StyledCustomizeButton,
  StyledMainInfoContainer,
  StyledMainInfoContent,
  StyledMainInfoHeader,
  StyledProp,
  StyledPropsContainer,
  StyledWelcomeScreenContainer
} from "./styles";

interface IProps {
  imgSrc: IMediaProps["landingImageURL"];
  onChangeActiveStep: (step: number) => void;
}

const Component = ({ imgSrc, onChangeActiveStep }: IProps) => (
  <StyledWelcomeScreenContainer imgSrc={imgSrc}>
    <StyledMainInfoContainer>
      <StyledMainInfoHeader>
        <h1 className="mainHeader">THE ALL-NEW</h1>
        <h1>SUPER GT 900</h1>
      </StyledMainInfoHeader>
      <StyledMainInfoContent>
        The iconic super car GT 900, unites beauty
        <br />
        and performance to create a breathtaking
        <br />
        driving experience.
      </StyledMainInfoContent>
      <StyledPropsContainer>
        {carProps.map(prop => (
          <StyledProp percent={prop.percent}>
            <Box className="rate">
              <Box className="header">{prop.title}</Box>
              <Box className="rateContainer">
                <Box className="ratePoint"></Box>
              </Box>
            </Box>
            <Box className="valueBlock">{prop.value}</Box>
          </StyledProp>
        ))}
      </StyledPropsContainer>
      <StyledCustomizeButton
        variant="contained"
        onClick={() => onChangeActiveStep(1)}
      >
        Customize your car <ChevronRightIcon />
      </StyledCustomizeButton>
    </StyledMainInfoContainer>
  </StyledWelcomeScreenContainer>
);

export default Component;
