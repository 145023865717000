// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import CloseIcon from "@mui/icons-material/Close";
// import { Box, Button, CircularProgress, Dialog } from "@mui/material";
// import { StyledCloseButton } from "components/ToggledView/styles";
// import VideoPlayer from "components/Videoplayer";
import { IBookFormProps } from "models/Landing";
import { IMediaProps } from "models/Media";
import React from "react";
import {
  // StyledAppointmentButtonContainer,
  StyledAppointmentContainer,
  StyledAppointmentContainerOverlay,
  StyledAppointmentContent
} from "./styles";

interface IProps {
  name: IBookFormProps["name"];
  exploreMode: boolean;
  isIframeLoading: boolean;
  isVideoOpen: boolean;
  onChangeMode: () => void;
  onIframeLoaded: () => void;
  onOpenCloseVideo: () => void;
  videoSrc: IMediaProps["videoURL"];
}

const Component = ({
  name,
  exploreMode,
  isIframeLoading,
  isVideoOpen,
  onChangeMode,
  onIframeLoaded,
  onOpenCloseVideo,
  videoSrc
}: IProps) => (
  <StyledAppointmentContainer>
    <StyledAppointmentContainerOverlay />
    <StyledAppointmentContent>
      <h3>
        Implementation of Omnireality Showroom in progress.
      </h3>
    </StyledAppointmentContent>
  </StyledAppointmentContainer>
  // <StyledAppointmentContainer>
  //   {exploreMode ? (
  //     <>
  //       {isIframeLoading && (
  //         <Box>
  //           <CircularProgress color="secondary" size={"6vw"} />
  //         </Box>
  //       )}
  //       <iframe
  //         className={isIframeLoading ? "" : "active"}
  //         onLoad={onIframeLoaded}
  //         src={"https://detroitlab.surrealevents.com/"}
  //       />
  //     </>
  //   ) : (
  //     <StyledAppointmentContent>
  //       <h1 className="mainHeader">HI {name}, WELCOME TO THE</h1>
  //       <h1 className="secondaryHeader">AUTOMOTIVERSE</h1>
  //       <StyledAppointmentButtonContainer direction="row" spacing={8}>
  //         <Button color="secondary" variant="contained" onClick={onChangeMode}>
  //           Explore <ChevronRightIcon />
  //         </Button>
  //         <Button
  //           color="secondary"
  //           variant="contained"
  //           onClick={onOpenCloseVideo}
  //         >
  //           Watch video <ChevronRightIcon />
  //         </Button>
  //       </StyledAppointmentButtonContainer>
  //     </StyledAppointmentContent>
  //   )}

  //   <Dialog open={isVideoOpen} fullWidth maxWidth="xl">
  //     <VideoPlayer forceStop={!isVideoOpen} videoSrc={videoSrc} />
  //     <StyledCloseButton color="secondary" onClick={onOpenCloseVideo}>
  //       <CloseIcon />
  //     </StyledCloseButton>
  //   </Dialog>
  // </StyledAppointmentContainer>
);

export default Component;
