import { Box } from "@mui/material";
import TitleSVG from "assets/Brochure/title.svg";
import { SLIDES } from "constants/brochure";
import { IBrochureImagesProps } from "models/Brochure";
import { IBookFormProps } from "models/Landing";
import React from "react";
import {
  StyledMainSlideContent,
  StyledMainSlideContentText,
  StyledMainSlideContentWrapper,
  StyledSlideContainer
} from "./styles";

interface IProps {
  bgSrc: IBrochureImagesProps["mainScreenImageURL"];
  name: IBookFormProps["name"];
}

const MainSlide = ({ bgSrc, name }: IProps) => (
  <StyledSlideContainer
    bgSrc={bgSrc}
    count={SLIDES.MAIN.count}
    type={SLIDES.MAIN.type}
    flipped
  >
    <StyledMainSlideContentWrapper>
      <StyledMainSlideContent>
        <StyledMainSlideContentText>
          <Box className="title">Hello {name},</Box>
          <Box className="subtitle">this is your new car!</Box>
          <Box className="body">
            The iconic <b>SUPER GT900</b>, unites beauty <br />
            and performance to create a breathtaking driving <br />
            experience.
          </Box>
        </StyledMainSlideContentText>

        <TitleSVG viewBox="0 0 905 354" />
      </StyledMainSlideContent>
    </StyledMainSlideContentWrapper>
  </StyledSlideContainer>
);

export default MainSlide;
