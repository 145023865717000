import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Button } from "@mui/material";
import { IConfigProps } from "models/Landing";
import React from "react";
import BookForm from "./BookForm";
import {
  StyledPreviewBox,
  StyledPreviewContainer,
  StyledPreviewScreenTextContainer,
  StyledTextContainer
} from "./styles";

interface IProps {
  config: IConfigProps[];
  isBookFormOpen: boolean;
  onBookFormOpenClose: () => void;
}

const Component = ({ config, isBookFormOpen, onBookFormOpenClose }: IProps) => (
  <StyledPreviewContainer>
    {config.map(({ imgSrc, title, rightAligned }) => (
      <StyledPreviewBox imgSrc={imgSrc} rightAligned={rightAligned}>
        {title ? (
          <>
            <img alt={title} src={imgSrc} />
            <StyledTextContainer>{title}</StyledTextContainer>
          </>
        ) : (
          <StyledPreviewScreenTextContainer>
            <Box>Book your virtual sales appointment</Box>
            <Button
              variant="contained"
              color="secondary"
              onClick={onBookFormOpenClose}
            >
              Book Here <ChevronRightIcon />
            </Button>
          </StyledPreviewScreenTextContainer>
        )}
      </StyledPreviewBox>
    ))}
    {isBookFormOpen && <BookForm onBookFormOpenClose={onBookFormOpenClose} />}
  </StyledPreviewContainer>
);

export default Component;
