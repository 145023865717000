import { UseCurrentCarConfigReturnType } from "components/WebGLConfigurator/WebGL";
import UIMenu from "components/WebglControls/UIMenu";
import {
  CarConfig,
  CarModel,
  CurrentCarConfigState
} from "contexts/ConfigContext";
import { MVStartRenderOptions } from "mv-webgl-core/models/camera";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

interface IProps {
  carModel: CarModel;
  loadCar: (
    configCodes: string[],
    startRenderOptions?: MVStartRenderOptions
  ) => Promise<void>;
  loadCamera: (name: string) => void;
  useCurrentCarConfig: () => UseCurrentCarConfigReturnType;
}

const Component = ({
  carModel,
  loadCar,
  loadCamera,
  useCurrentCarConfig
}: IProps) => {
  const [currentCarConfig, setCurrentCarConfig] = useCurrentCarConfig();
  const [currentEditable, setCurrentEditable] = useState("");
  const [activeOptionLabel, setActiveOptionLabel] = useState("");
  const [availableOptions, setAvailableOptions] = useState([]);
  const [prevEdit, setPrevEdit] = useState("carpaint");
  const [loading, setLoading] = useState(false);
  const carConfigState = useSelector(
    (state: RootState) => state.webGLConfigurator.carConfig
  );

  useEffect(() => {
    if (carModel) {
      const newCarConfigState: CurrentCarConfigState[] = [];
      carModel.carConfigProperties.forEach(configProperty => {
        // @ts-ignore
        const defaultCarConfig = carConfigState[configProperty.id]
          ? // @ts-ignore
            carConfigState[configProperty.id]
          : carModel.carConfigs.find(
              carConfig =>
                carConfig.configProperty === configProperty.id &&
                carConfig.default === true
            );

        if (defaultCarConfig === undefined) {
          throw new Error(`Couldn't find a default car config entry for the config property "${configProperty.id}"!
          Add property "default": true to the default "${configProperty.id}" entry in "carConfigs" in the config.`);
        }

        newCarConfigState.push({
          ...configProperty,
          carConfig: defaultCarConfig,
          carConfigMenuOpen: false
        });
      });

      setCurrentCarConfig(newCarConfigState);
    }
  }, [carModel, setCurrentCarConfig]);

  const carConfigChangeHandler = (label: string) => {
    return async (carConfig: CarConfig): Promise<void> => {
      // Prevent loading new configuration after previous has finished loading
      if (loading) {
        return;
      }
      setLoading(true);

      const newCarConfigState = currentCarConfig.map(configOption => {
        if (configOption.label !== label) return configOption;
        configOption.carConfigMenuOpen = true;
        configOption.carConfig = carConfig;
        return configOption;
      });

      setCurrentCarConfig([...newCarConfigState]);
      const configCodes = newCarConfigState.map(
        ({ carConfig }) => carConfig.configCode
      );

      const startRenderOptions: MVStartRenderOptions = {
        fadeOutPreviousFrame: true,
        fadeOutDurationInMilliSeconds: 500
      };

      await loadCar(configCodes, startRenderOptions);
      setLoading(false);

      setCurrentCarConfig([...newCarConfigState]);
    };
  };

  const handleSelectOption = (id: string, label: string) => {
    setCurrentEditable(id);
    setActiveOptionLabel(label);

    const options = carModel.carConfigs.filter(
      carConfig => carConfig.configProperty === id
    );
    // @ts-ignore
    setAvailableOptions(options);

    const configOption = currentCarConfig.find(
      (configOption: CurrentCarConfigState) => configOption.id === id
    );

    id !== prevEdit && loadCamera(configOption!.switchToCameraShotOnChange);
    setPrevEdit(id);
  };

  const handleConfigChange = (option: {
    id: string;
    label?: string | undefined;
    subLabel?: string | undefined;
    configProperty: string;
    configCode: string;
    iconPath?: string | undefined;
    default?: boolean | undefined;
    isLoading?: boolean | undefined;
  }) => {
    const changeConfig = carConfigChangeHandler(activeOptionLabel);
    changeConfig(option);
  };

  return (
    <UIMenu
      availableOptions={availableOptions}
      currentEditable={currentEditable}
      currentCarConfig={currentCarConfig}
      handleSelectOption={handleSelectOption}
      handleConfigChange={handleConfigChange}
    />
  );
};

export default Component;
