import PCIconSVG from "assets/Landing/BookForm/pc_icon.svg";
import TVIconSVG from "assets/Landing/BookForm/smart_tv_icon.svg";
import VRIconSVG from "assets/Landing/BookForm/vr_icon.svg";
import { IIterableItems } from "models/common";
import {
  IBookFormExpProps,
  IBookFormFieldsProps,
  IBookFormProps
} from "models/Landing";
import React from "react";

export const predefinedState: IBookFormProps = {
  name: "Jane",
  surname: "Doe",
  email: "janedoe@email.com",
  phone: "(305) 594-5555",
  appointmentDate: new Date()
    .toLocaleTimeString("en-US", {
      timeStyle: "short"
    })
    .replace(/:\d+ /, " "),
  dealerTime: new Date().toLocaleDateString().replace(/[.]/g, " / "),
  selectedItem: "PC"
};

export const bookFormFields: IIterableItems<IBookFormFieldsProps>[] = [
  {
    items: [
      { id: "name", label: "Name", placeholder: "Name..." },
      { id: "surname", label: "Surname", placeholder: "Surname..." }
    ]
  },
  {
    items: [
      { id: "email", label: "Email", placeholder: "name@email.com" },
      { id: "phone", label: "Phone", placeholder: "(000) 000 0000" }
    ]
  },
  {
    items: [
      {
        id: "appointmentDate",
        label: "Appointment date",
        placeholder: "mm / dd / yyyy"
      },
      {
        id: "dealerTime",
        label: "Dealer available time",
        placeholder: "Select a time..."
      }
    ]
  }
];

export const bookFormExpItems: IBookFormExpProps[] = [
  { id: "PC", label: "Desktop PC", icon: <PCIconSVG viewBox="0 0 48 48" /> },
  {
    id: "VR",
    label: "Virtual Reality",
    icon: <VRIconSVG viewBox="0 0 48 48" />
  },
  {
    id: "TV",
    label: "Smart TV",
    icon: <TVIconSVG viewBox="0 0 48 30" style={{ transform: "scale(0.8)" }} /> // fix svg sizes
  }
];
