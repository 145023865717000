import { Box, Button } from "@mui/material";
import BtnSVG from "assets/TopBar/btn.svg";
import LogoPathPNG from "assets/TopBar/header-icon.png";
import PurpleBarSVG from "assets/TopBar/shape.svg";
import WhiteTopBarSVG from "assets/TopBar/whiteTopBar.svg";
import { IViewModeProps } from "models/Layout";
import { MenuBarProps } from "models/menu";
import React from "react";
import {
  StyledHeaderTextContainer,
  StyledPurpleButtonContainer,
  StyledTopBarContainer,
  StyledWhiteBarContainer
} from "./styles";

interface IProps {
  animationDirection: IViewModeProps["animationDirection"];
  onSwitchViewMode: () => void;
  barHeight: MenuBarProps["barHeight"];
  barWidth: MenuBarProps["barWidth"];
  buttonHeight: MenuBarProps["buttonHeight"];
  buttonWidth: MenuBarProps["buttonWidth"];
  toggledView: IViewModeProps["toggledView"];
}

const Component = ({
  animationDirection,
  onSwitchViewMode,
  barHeight,
  barWidth,
  buttonHeight,
  buttonWidth,
  toggledView
}: IProps) => (
  <StyledTopBarContainer toggled={toggledView}>
    <StyledPurpleButtonContainer
      barHeight={barHeight}
      barWidth={barWidth}
      buttonHeight={buttonHeight}
      buttonWidth={buttonWidth}
      className={
        animationDirection
          ? animationDirection === "out"
            ? "animationIn"
            : "animationOut"
          : "initial"
      }
    >
      <BtnSVG viewBox="0 0 540 70" className="barBtn" />
      <Button disabled={!toggledView} onClick={onSwitchViewMode}></Button>
    </StyledPurpleButtonContainer>
    <PurpleBarSVG
      viewBox="0 0 1920 100"
      height={barHeight}
      width={barWidth}
      className="barShape"
    />
    <StyledWhiteBarContainer>
      <WhiteTopBarSVG viewBox="0 0 1920 130" width={barWidth} />
      <StyledHeaderTextContainer>
        <img alt="logo" src={LogoPathPNG} />
        <Box className="bold-text">SynOps&nbsp;|</Box>
        <Box className="regular-text">&nbsp;Next-Gen Content</Box>
      </StyledHeaderTextContainer>
    </StyledWhiteBarContainer>
  </StyledTopBarContainer>
);

export default Component;
