import { IWebGLConfiguratorProps } from "models/WebGLConfigurator";
import React from "react";
import ConfiguratorScreen from "./ConfiguratorScreen";
import LandingStepper from "./LandingStepper";
import PreviewScreen from "./PreviewScreen";
import {
  StyledScreensContainer,
  StyledScrollingScreenContainer
} from "./styles";
import WelcomeScreen from "./WelcomeScreen";

interface IProps {
  activeStep: number;
  carConfig: IWebGLConfiguratorProps["carConfig"];
  onChangeActiveStep: (step: number) => void;
}

const Component = ({ activeStep, carConfig, onChangeActiveStep }: IProps) => (
  <StyledScreensContainer>
    <StyledScrollingScreenContainer counter={activeStep}>
      <WelcomeScreen onChangeActiveStep={onChangeActiveStep} />
      {Object.values(carConfig).every(config => config) && (
        <ConfiguratorScreen />
      )}
      <PreviewScreen />
    </StyledScrollingScreenContainer>
    <LandingStepper
      activeStep={activeStep}
      setActiveStep={onChangeActiveStep}
    />
  </StyledScreensContainer>
);

export default Component;
