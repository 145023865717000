import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { sendEmail } from "store/reducers/Email";
import Component from "./component";
import { emailRegExp } from "./config";
import { setFormValues as setBookFormValues } from "../../store/reducers/BookForm";

type EmailFormModel = {
  name: string;
  email: string;
};

const FormComponent = () => {
  const dispatch = useDispatch();
  const bookForm = useSelector((state: RootState) => state.bookForm);
  const initialFormState: EmailFormModel = {
    name: bookForm.name,
    email: bookForm.email,
  };

  const [formValues, setFormValues] = useState(initialFormState);
  const [error, setError] = useState("");

  const handleOnChangeValue = (value: { [key: string]: string }) =>
    setFormValues({ ...formValues, ...value });

  const handleOnSubmit = () => {
    if (formValues.email && emailRegExp.test(formValues.email)) {
      setError("");
      // also put name/email into booking form.
      dispatch(setBookFormValues({...bookForm, ...formValues}))
      // send mail, which will pull values from the booking form
      dispatch(sendEmail());
    } else {
      setError(
        !formValues.email
          ? "This field is required"
          : "should be like this name@mail.com"
      );
    }
  };

  return (
    <Component
      error={error}
      formValues={formValues}
      onChange={handleOnChangeValue}
      onSubmit={handleOnSubmit}
    />
  );
};

export default FormComponent;
