import { Box, Button } from "@mui/material";
import { styled } from "@mui/system";

export const StyledArticleBody = styled("span")({
  color: "#616161",
  fontFamily: "Montserrat light",
  fontSize: "0.7vw",
  lineHeight: 1.5
});

export const StyledArticleCategory = styled("span")({
  fontFamily: "Montserrat",
  fontSize: "0.7vw",
  lineHeight: 1.5
});

export const StyledArticleFooter = styled("span")({
  color: "#bdbdbd",
  fontSize: "0.5vw",
  fontFamily: "Montserrat light",
  marginTop: "1vh",
  "&.imageBlock": {
    margin: 0
  }
});

export const StyledArticleTitle = styled("span")({
  fontSize: "1.75vw",
  fontFamily: "Noe Display",
  lineHeight: 1.5
});

export const StyledImageArticle = styled(Box)({
  display: "flex",
  flexDirection: "row",
  padding: "0 !important",
  div: {
    padding: "0 1.25vw !important"
  },
  img: {
    width: "7vw",
    height: "8.5vh",
    margin: 0
  }
});

export const StyledImageButton = styled(Button)({
  position: "absolute",
  bottom: "2.5vh",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background:
    "linear-gradient(133.77deg, rgba(229, 244, 250, 0.6) 23.29%, rgba(229, 244, 250, 0.2) 74.46%)",
  border: "1px solid rgba(255, 255, 255, 0.7)",
  boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(10px)",
  color: "black",
  borderRadius: 40,
  fontFamily: "GraphikBold",
  fontSize: "0.75vw",
  textTransform: "none",
  "&:hover": {
    background:
      "linear-gradient(133.77deg, rgba(229, 244, 250, 0.8) 23.29%, rgba(229, 244, 250, 0.4) 74.46%)"
  },
  animation: "appearBtn 1s",
  "@keyframes appearBtn": {
    "0%": {
      opacity: 0
    },
    "100%": {
      opacity: 0.75
    }
  }
});

export const StyledImageContainer = styled(Box)({
  position: "relative",
  padding: "0 !important",
  marginBottom: "auto",
  height: "100%",
  width: "100%",
  ".loaderContainer": {
    display: "flex",
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center"
  }
});

export const StyledImageText = styled(Box)({
  color: "white",
  position: "absolute",
  left: "1vw",
  top: "5vh",
  borderLeft: "6px solid white",
  height: "fit-content",
  padding: "0.5vh 0 0.5vh 1vw!important",
  h2: {
    fontSize: "1.25vw",
    lineHeight: 1,
    margin: "0.25vh 0",
    fontFamily: "GraphikBold",
    "&.mainHeader": {
      fontFamily: "Graphik",
      animation: "appearMainHeader 1s",
      "@keyframes appearMainHeader": {
        "0%": {
          opacity: 0
        },
        "50%": {
          opacity: 0
        },
        "100%": {
          opacity: 1
        }
      }
    },
    animation: "appear 1s",
    "@keyframes appear": {
      "0%": {
        opacity: 0
      },
      "75%": {
        opacity: 0
      },
      "100%": {
        opacity: 1
      }
    }
  },
  animation: "slideDown .5s",
  "@keyframes slideDown": {
    "0%": {
      top: "0%"
    },
    "100%": {
      top: "5vh"
    }
  }
});
