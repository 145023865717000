import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { Box, Fab, Slider } from "@mui/material";
import React, { SyntheticEvent } from "react";
import {
  StyledControlsContainer,
  StyledVideoWrapper,
  StyledVolumeController
} from "./styles";

interface IProps {
  duration: number;
  isMuted: boolean;
  isPlaying: boolean;
  onChangeTime: (_: any, value: number | number[]) => void;
  onChangeTimeCommitted: () => void;
  onChangeVolume: (_: any, value: number | number[]) => void;
  onLoadMetaData: (value: SyntheticEvent<HTMLVideoElement>) => void;
  onMuteUnmute: () => void;
  onPlayPause: () => void;
  posterSrc?: string;
  videoRef: React.RefObject<HTMLVideoElement>;
  time: number;
  timeValueLabelFormat: (value: number) => string;
  videoSrc?: string;
  volume: number;
  volumeValueLabelFormat: (value: number) => string;
}

const Component = ({
  duration,
  isMuted,
  isPlaying,
  onChangeTime,
  onChangeTimeCommitted,
  onChangeVolume,
  onLoadMetaData,
  onMuteUnmute,
  onPlayPause,
  posterSrc,
  videoRef,
  time,
  timeValueLabelFormat,
  videoSrc,
  volume,
  volumeValueLabelFormat
}: IProps) => (
  <StyledVideoWrapper>
    <video
      poster={posterSrc}
      ref={videoRef}
      onLoadedMetadata={onLoadMetaData}
      width="100%"
      height="100%"
      onClick={onPlayPause}
    >
      <source src={videoSrc} />
    </video>
    <StyledControlsContainer spacing={2} direction="row">
      <Fab onClick={onPlayPause} size="small" color="secondary">
        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
      </Fab>
      <Slider
        color="secondary"
        size="small"
        valueLabelDisplay="auto"
        onChange={onChangeTime}
        onChangeCommitted={onChangeTimeCommitted}
        step={1}
        max={duration || 0}
        valueLabelFormat={timeValueLabelFormat}
        value={time}
      />
      <StyledVolumeController>
        <Box className={"slider"}>
          <Box>
            <Slider
              color="secondary"
              orientation="vertical"
              size="small"
              valueLabelDisplay="auto"
              onChange={onChangeVolume}
              valueLabelFormat={volumeValueLabelFormat}
              value={volume}
            />
          </Box>
        </Box>
        <Fab size="small" color="secondary" onClick={onMuteUnmute}>
          {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </Fab>
      </StyledVolumeController>
    </StyledControlsContainer>
  </StyledVideoWrapper>
);

export default Component;
