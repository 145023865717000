import { IArticleProps } from "models/Advert";
import React from "react";
import {
  StyledArticleBody,
  StyledArticleCategory,
  StyledArticleFooter,
  StyledArticleTitle
} from "./styles";

interface IProps {
  article: IArticleProps;
}

const RegularArticle = ({ article }: IProps) => (
  <>
    {article.img && (
      <img src={article.img.src} alt={article.img.alt} width="100%" />
    )}
    {article.category && (
      <StyledArticleCategory>{article.category}</StyledArticleCategory>
    )}
    <StyledArticleTitle>{article.title}</StyledArticleTitle>
    <StyledArticleBody>{article.body}</StyledArticleBody>
    <StyledArticleFooter>{article.footer}</StyledArticleFooter>
  </>
);

export default RegularArticle;
