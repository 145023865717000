import AdvertStepSVG from "assets/BottomBar/advertise.svg";
import AppointmentStepSVG from "assets/BottomBar/appointment.svg";
import BrochureStepSVG from "assets/BottomBar/brochure.svg";
import EmailStepSVG from "assets/BottomBar/email.svg";
import LandingStepSVG from "assets/BottomBar/landingMenu.svg";
import { APP_ROUTES } from "constants/routing";
import React from "react";

export const MENU_ITEMS_VALUE = {
  HOME: "home",
  ADVERT: "advert",
  LANDING: "landing",
  APPOINTMENT: "appointment",
  EMAIL: "email",
  BROCHURE: "brochure",
  SUMMARY: "summary"
};

export const MENU_ITEMS = [
  {
    title: "Home Screen",
    route: APP_ROUTES.HOME,
    value: MENU_ITEMS_VALUE.HOME,
    isEdge: true
  },
  {
    title: "Digital Advertising",
    route: APP_ROUTES.ADVERT,
    value: MENU_ITEMS_VALUE.ADVERT,
    svg: ({ barHeight, barWidth }: { barHeight: number; barWidth: number }) => (
      <AdvertStepSVG
        viewBox="0 0 1920 200"
        height={barHeight}
        width={barWidth}
        className="whiteBarShape"
      />
    ),
    isMiddle: true
  },
  {
    title: "Landing Page",
    route: APP_ROUTES.LANDING,
    value: MENU_ITEMS_VALUE.LANDING,
    svg: ({ barHeight, barWidth }: { barHeight: number; barWidth: number }) => (
      <LandingStepSVG
        viewBox="0 0 1920 200"
        height={barHeight}
        width={barWidth}
        className="whiteBarShape"
      />
    ),
    isCenter: true
  },
  {
    title: "Virtual Sales Appointment",
    route: APP_ROUTES.APPOINTMENT,
    value: MENU_ITEMS_VALUE.APPOINTMENT,
    svg: ({ barHeight, barWidth }: { barHeight: number; barWidth: number }) => (
      <AppointmentStepSVG
        viewBox="0 0 1920 200"
        height={barHeight}
        width={barWidth}
        className="whiteBarShape"
      />
    ),
    isCenter: true
  },
  {
    title: "Follow-Up Email",
    route: APP_ROUTES.EMAIL,
    value: MENU_ITEMS_VALUE.EMAIL,
    svg: ({ barHeight, barWidth }: { barHeight: number; barWidth: number }) => (
      <EmailStepSVG
        viewBox="0 0 1920 200"
        height={barHeight}
        width={barWidth}
        className="whiteBarShape"
      />
    ),
    isMiddle: true
  },
  {
    title: "Digital Brochure",
    route: APP_ROUTES.BROCHURE,
    value: MENU_ITEMS_VALUE.BROCHURE,
    svg: ({ barHeight, barWidth }: { barHeight: number; barWidth: number }) => (
      <BrochureStepSVG
        viewBox="0 0 1920 200"
        height={barHeight}
        width={barWidth}
        className="whiteBarShape"
      />
    ),
    isEdge: true
  },
  {
    title: "Experience Summary",
    route: APP_ROUTES.SUMMARY,
    value: MENU_ITEMS_VALUE.SUMMARY
  }
];
