import Advert from "components/Advert";
import Appointment from "components/Appointment";
import Brochure from "components/Brochure";
import Email from "components/Email";
import Home from "components/Home";
import Landing from "components/Landing";
import Layout from "components/Layout";
import Summary from "components/Summary";
import { APP_ROUTES } from "constants/routing";
import React from "react";
import { Route, Routes } from "react-router-dom";

const appRouting = () => (
  <Routes>
    <Route path="/">
      <Route index element={<Home />} />
      <Route path={"/"} element={<Layout />}>
        <Route path={APP_ROUTES.ADVERT} element={<Advert />} />
        <Route path={APP_ROUTES.LANDING} element={<Landing />} />
        <Route path={APP_ROUTES.APPOINTMENT} element={<Appointment />} />
        <Route path={APP_ROUTES.EMAIL} element={<Email />} />
        <Route path={APP_ROUTES.BROCHURE} element={<Brochure />} />
        <Route path={APP_ROUTES.SUMMARY} element={<Summary />} />
      </Route>
    </Route>
  </Routes>
);

export default appRouting;
