import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TOGGLED_VIEW_TYPES } from "constants/toggledView";
import { initialState } from "models/ToggledView";

export const ToggledViewSlice = createSlice({
  name: "toggledView",
  initialState,
  reducers: {
    setToggledViewType: (
      state,
      action: PayloadAction<TOGGLED_VIEW_TYPES | null>
    ) => ({
      type: action.payload
    })
  }
});

export const { setToggledViewType } = ToggledViewSlice.actions;

export default ToggledViewSlice.reducer;
