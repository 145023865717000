import { getCarImageUrl } from "api/utils";
import {
  IMAGE_BACKGROUND_IDS,
  IMAGE_FILE_TYPE,
  IMAGE_PERSPECTIVE_IDS,
  IMAGE_RESOLUTIONS
} from "constants/images";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { RootState } from "store";
import { setBrochureImages } from "store/reducers/Brochure";
import { setImages, setVideo } from "store/reducers/Media";
import { setCarOptionConfig } from "store/reducers/WebGLConfigurator";
import { getImage, getVideo } from "./utils";

export type TPerspectiveEnum = keyof typeof IMAGE_PERSPECTIVE_IDS;
export type TBackgroundEnum = keyof typeof IMAGE_BACKGROUND_IDS;

function* handleLoadImage(): Generator {
  const config = yield select(
    (state: RootState) => state.webGLConfigurator.carConfig
  );
  const perspectiveIds = Object.keys(IMAGE_PERSPECTIVE_IDS);
  let randomPerspective = perspectiveIds[
    Math.floor(Math.random() * perspectiveIds.length)
  ] as TPerspectiveEnum;

  const backgroundIds = Object.keys(IMAGE_BACKGROUND_IDS);
  let randomBackground = backgroundIds[
    Math.floor(Math.random() * backgroundIds.length)
  ] as TBackgroundEnum;
  try {
    // @ts-ignore
    const [
      advertImageResponse,
      landingImageResponse,
      emailImageResponse,
      carpaintImageResponse,
      rimsImageResponse,
      interiorImageResponse
    ] = yield all([
      call(getImage, [
        {
          ...getCarImageUrl(config),
          perspective_id: IMAGE_PERSPECTIVE_IDS[randomPerspective],
          background_id: IMAGE_BACKGROUND_IDS[randomBackground]
        }
      ]),
      call(getImage, [
        {
          ...getCarImageUrl(config),
          resolution: IMAGE_RESOLUTIONS.FULL_HD
        }
      ]),
      call(getImage, [
        {
          ...getCarImageUrl(config),
          resolution: IMAGE_RESOLUTIONS.FULL_HD,
          perspective_id: IMAGE_PERSPECTIVE_IDS.FRONT_LEFT
        }
      ]),
      call(getImage, [
        {
          ...getCarImageUrl(config),
          resolution: IMAGE_RESOLUTIONS.TABLET,
          perspective_id: IMAGE_PERSPECTIVE_IDS.FRONT_RIGHT
        }
      ]),
      call(getImage, [
        {
          ...getCarImageUrl(config),
          resolution: IMAGE_RESOLUTIONS.TABLET,
          perspective_id: IMAGE_PERSPECTIVE_IDS.SIDE_RIGHT
        }
      ]),
      call(getImage, [
        {
          ...getCarImageUrl(config),
          resolution: IMAGE_RESOLUTIONS.TABLET,
          perspective_id: IMAGE_PERSPECTIVE_IDS.INTERIOR
        }
      ])
    ]);
    yield put(
      setImages({
        advertImageURL: advertImageResponse.url,
        landingImageURL: landingImageResponse.url,
        emailImageURL: emailImageResponse.url,
        carpaintImageURL: carpaintImageResponse.url,
        rimsImageURL: rimsImageResponse.url,
        interiorImageURL: interiorImageResponse.url
      })
    );
    // @ts-ignore
    const video = yield call(getVideo, [
      {
        ...getCarImageUrl(config),
        resolution: IMAGE_RESOLUTIONS.HDTV,
        perspective_id: "MVT_CineCamera6",
        file_type: IMAGE_FILE_TYPE.MP4
      }
    ]);
    //@ts-ignore
    yield put(setVideo(video.url));
    //@ts-ignore
    const [
      mainScreenImageResponse,
      detail1ImageResponse,
      detail2ImageResponse,
      detail3ImageResponse,
      interiorFullHDImagesResponse,
      rimsFullHDImagesResponse,
      frontViewFullHDImageResponse
    ] = yield all([
      call(getImage, [
        {
          ...getCarImageUrl(config),
          resolution: IMAGE_RESOLUTIONS.FULL_HD,
          perspective_id: IMAGE_PERSPECTIVE_IDS.SIDE_LEFT,
          background_id: IMAGE_BACKGROUND_IDS.SUNSET
        }
      ]),
      call(getImage, [
        {
          ...getCarImageUrl(config),
          resolution: IMAGE_RESOLUTIONS.FULL_HD,
          perspective_id: IMAGE_PERSPECTIVE_IDS.FRONT_LEFT,
          background_id: IMAGE_BACKGROUND_IDS.SUNSET
        }
      ]),
      call(getImage, [
        {
          ...getCarImageUrl(config),
          resolution: IMAGE_RESOLUTIONS.FULL_HD,
          perspective_id: IMAGE_PERSPECTIVE_IDS.REAR_RIGHT,
          background_id: IMAGE_BACKGROUND_IDS.SUNSET
        }
      ]),
      call(getImage, [
        {
          ...getCarImageUrl(config),
          resolution: IMAGE_RESOLUTIONS.FULL_HD,
          perspective_id: IMAGE_PERSPECTIVE_IDS.DETAIL_2,
          background_id: IMAGE_BACKGROUND_IDS.SUNSET
        }
      ]),
      call(getImage, [
        {
          ...getCarImageUrl(config),
          resolution: IMAGE_RESOLUTIONS.FULL_HD,
          perspective_id: IMAGE_PERSPECTIVE_IDS.INTERIOR
        }
      ]),
      call(getImage, [
        {
          ...getCarImageUrl(config),
          resolution: IMAGE_RESOLUTIONS.FULL_HD,
          perspective_id: IMAGE_PERSPECTIVE_IDS.DETAIL_3,
          background_id: IMAGE_BACKGROUND_IDS.DARK
        }
      ]),
      call(getImage, [
        {
          ...getCarImageUrl(config),
          resolution: IMAGE_RESOLUTIONS.FULL_HD,
          perspective_id: IMAGE_PERSPECTIVE_IDS.FRONT,
          background_id: IMAGE_BACKGROUND_IDS.DARK
        }
      ])
    ]);
    yield put(
      setBrochureImages({
        mainScreenImageURL: mainScreenImageResponse.url,
        detailsImagesURL: [
          detail1ImageResponse.url,
          detail2ImageResponse.url,
          detail3ImageResponse.url
        ],
        interiorImageURL: interiorFullHDImagesResponse.url,
        rimsImageURL: rimsFullHDImagesResponse.url,
        frontViewImageURL: frontViewFullHDImageResponse.url
      })
    );
  } catch (error) {
    // try to handle error
    console.log(error);
  }
}

export default function* watchImagesSaga() {
  yield takeEvery(setCarOptionConfig, handleLoadImage);
}
