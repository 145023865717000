import { Box, Divider, Stack } from "@mui/material";
import { IMediaProps } from "models/Media";
import React from "react";
import ImageArticle from "./articles/ImageArticle";
import RegularArticle from "./articles/RegularArticle";
import ShortArticle from "./articles/ShortArticle";
import { advertColumns, advertNavigationTitles } from "./config";
import {
  StyledAdvertContainer,
  StyledAdvertContent,
  StyledAdvertHeader,
  StyledAdvertNavigationContainer
} from "./styles";

interface IProps {
  imgUrl: IMediaProps["advertImageURL"];
  isImageLoaded: boolean;
  onImageLoad: () => void;
  onNavigate: () => void;
}

const AdvertMainViewMode = ({
  imgUrl,
  isImageLoaded,
  onImageLoad,
  onNavigate
}: IProps) => (
  <StyledAdvertContainer>
    <StyledAdvertHeader>Daily World News</StyledAdvertHeader>
    <StyledAdvertNavigationContainer>
      {advertNavigationTitles.map(title => (
        <Box>{title}</Box>
      ))}
    </StyledAdvertNavigationContainer>
    <StyledAdvertContent>
      {advertColumns.map((column, index) => (
        <Stack
          flex={2 - index * 0.25} // columns sizes 2, 1.75, 1.5
          className={index === advertColumns.length - 1 ? "lastColumn" : ""}
        >
          {column.items.map((article, articleIndex) => (
            <>
              {article.isDynamicImage ? (
                <ImageArticle
                  isImageLoaded={isImageLoaded}
                  onImageLoad={onImageLoad}
                  onNavigate={onNavigate}
                  imgUrl={imgUrl}
                />
              ) : article.isShortArticle ? (
                <ShortArticle article={article} />
              ) : (
                <RegularArticle article={article} />
              )}
              {articleIndex !== column.items.length - 1 &&
                !article.isDynamicImage && (
                  <Divider className="articleDivider" />
                )}
            </>
          ))}
        </Stack>
      ))}
    </StyledAdvertContent>
  </StyledAdvertContainer>
);

export default AdvertMainViewMode;
