import { call, select, takeEvery } from "redux-saga/effects";
import { RootState } from "store";
import { sendEmail } from "store/reducers/Email";
import { doSendMail, IEmailPayload } from "../api/email";
import { ICarConfigProps } from "../models/WebGLConfigurator";
import {
  IMAGE_BACKGROUND_IDS,
  IMAGE_CAR_COLORS,
  IMAGE_CAR_INTERIOR_COLORS,
  IMAGE_CAR_RIMS,
  IMAGE_PERSPECTIVE_IDS
} from "../constants/images";
import { IBookFormProps } from "../models/Landing";

function* handleSendEmail(): Generator {
  const config = (yield select(
    (state: RootState) => state.webGLConfigurator.carConfig
  )) as ICarConfigProps;
  const bookForm = (yield select((state: RootState) => state.bookForm)) as IBookFormProps;

  const mail: IEmailPayload = {
    background_id: IMAGE_BACKGROUND_IDS.WHITE_ROOM,
    click_url: "https://nextgen-content.accenture.com/",
    email: bookForm.email,
    interior: config.interior?.urlValue,
    name: bookForm.name,
    paint: config.carpaint?.urlValue,
    perspective_id: IMAGE_PERSPECTIVE_IDS.FRONT_LEFT,
    rims: config.rims?.urlValue
  }

  try {
    yield call(doSendMail, mail);
  } catch (error) {
    console.error("failed to send email", error);
  }
}

export default function* watchImagesSaga() {
  yield takeEvery(sendEmail, handleSendEmail);
}
