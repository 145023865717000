import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { ILayoutContainerProps } from "models/Layout";

export const StyledBottomBarContainer = styled(Box)(
  ({ IframeMarginBottom }: { IframeMarginBottom: number }) => ({
    position: "absolute",
    bottom: "0",
    maxHeight: 170,
    zIndex: 10,
    transition: "all 1s",
    "&.hide": {
      bottom: -IframeMarginBottom - 2
    }
  })
);

export const StyledContentContainer = styled(Box)({
  position: "absolute",
  top: "0",
  height: "100vh"
});

export const StyledLayoutContainer = styled(Box)(
  ({ toggledView }: ILayoutContainerProps) => ({
    position: "relative",
    background: toggledView
      ? "linear-gradient(52deg, rgba(122,116,156,1) 0%, rgba(240,235,255,1) 50%, rgba(122,116,156,1) 100%)"
      : "#EBEBEB",
    height: "100vh",
    overflow: "hidden"
  })
);
export const StyledSliderContainer = styled(Box)({
  position: "absolute",
  zIndex: 9,
  "&,initial": { bottom: "-120%" },
  "&.animationFromTopToBottom": {
    animation: "fromTopToBottom 2s",
    "@keyframes fromTopToBottom": {
      "0%": {
        bottom: "120%"
      },
      "100%": {
        bottom: "-120%"
      }
    }
  },
  "&.animationFromBottomToTop": {
    animation: "fromBottomToTop 2s",
    "@keyframes fromBottomToTop": {
      "0%": {
        bottom: "-120%"
      },
      "100%": {
        bottom: "120%"
      }
    }
  }
});

export const StyledTopBarContainer = styled(Box)({
  position: "absolute",
  top: "0",
  width: "100%",
  height: "12vh",
  zIndex: 10,
  transition: "all 1s",
  "&.hide": {
    top: "-12vh"
  }
});
