import { APP_ROUTES } from "constants/routing";
import { TOGGLED_VIEW_TYPES } from "constants/toggledView";

export type ToggledViewConfigModel = {
  title: string;
  subTitle: string;
  imgSrc: string;
  videoSrc: string;
  body: string[];
  actionComponent?: any;
};

export interface IToggledViewProps {
  type: TOGGLED_VIEW_TYPES | null;
}
export const initialState: IToggledViewProps = {
  type: null
};

export const ToggledViewTypeByRoute = {
  [APP_ROUTES.HOME]: null,
  [APP_ROUTES.ADVERT]: TOGGLED_VIEW_TYPES.ADVERT,
  [APP_ROUTES.LANDING]: TOGGLED_VIEW_TYPES.LANDING,
  [APP_ROUTES.APPOINTMENT]: TOGGLED_VIEW_TYPES.APPOINTMENT,
  [APP_ROUTES.EMAIL]: TOGGLED_VIEW_TYPES.EMAIL,
  [APP_ROUTES.BROCHURE]: TOGGLED_VIEW_TYPES.BROCHURE,
  [APP_ROUTES.SUMMARY]: null
};
