import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { switchViewMode } from "store/reducers/ViewMode";
import { useSVGSizes } from "utils/resizeCustomHook";
import useToggledView from "utils/toggleCustomHook";
import Component from "./component";

const TopBar = () => {
  const dispatch = useDispatch();
  const { toggledView } = useToggledView();
  const { topBar, toggleButton } = useSVGSizes();

  const animationDirection = useSelector(
    (state: RootState) => state.viewMode.animationDirection
  );

  const handleSwitchViewMode = () => dispatch(switchViewMode());

  return (
    <Component
      animationDirection={animationDirection}
      onSwitchViewMode={handleSwitchViewMode}
      barWidth={topBar.width}
      barHeight={topBar.height}
      buttonHeight={toggleButton.height}
      buttonWidth={toggleButton.width}
      toggledView={toggledView}
    />
  );
};

export default TopBar;
