import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const StyledAdvertContainer = styled(Box)({
  height: "100vh",
  overflow: "hidden",
  padding: "12vh 10vw 18vh 10vw"
});

export const StyledAdvertContent = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  div: {
    padding: "3.5vh 2.5vw 0 0",
    "&.lastColumn": {
      padding: "3.5vh 0 0 0"
    }
  },
  ".articleDivider": { margin: "2vh 0" }
});

export const StyledAdvertHeader = styled(Box)({
  display: "flex",
  justifyContent: "center",
  fontFamily: "Chomsky",
  fontSize: "3.5vw"
});

export const StyledAdvertNavigationContainer = styled(Box)({
  margin: "0.5vh 0",
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "3px solid grey",
  div: {
    fontSize: "0.8vw",
    padding: "4px 0",
    fontFamily: "Montserrat",
    ":last-of-type": {
      padding: 4,
      borderBottom: "6px solid"
    }
  }
});
