import { Box, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledFieldsContainer = styled(Stack)({ position: "relative" });

export const StyledFormContainer = styled(Box)({
  width: "100%",
  height: "100%",
  button: {
    position: "absolute",
    top: "50%",
    right: 0,
    transform: "translateY(-50%)",
    height: "100%",
    width: "25%",
    margin: 0,
    borderRadius: 32,
    textTransform: "none",
    padding: "0.6vh 0.8vw",
    fontSize: "0.7vw",
    boxShadow: "0px 15px 20px -5px rgb(0 86 179 / 30%)",
    backgroundColor: "#0056B3"
  }
});

export const StyledEmailFormInputContainer = styled(Box)(
  ({ hasError, cut }: { hasError: boolean; cut?: boolean }) => ({
    flex: cut ? 0.8 : 1,
    position: "relative",
    label: {
      color: hasError ? "#FF5733" : "black",
      fontSize: "0.6vw",
      position: "absolute",
      top: "-2vh",
      left: "0.4vw",
      fontWeight: "bold"
    },
    ".errorText": {
      color: "#FF5733",
      fontSize: "0.6vw",
      position: "absolute",
      bottom: "-2vh",
      left: "0.4vw",
      fontWeight: "bold"
    },
    input: {
      padding: "0.4vw!important",
      backgroundColor: "white",
      border: `2px solid ${hasError ? "#FF5733" : "transparent"}`,
      borderRadius: 6,
      fontSize: "0.8vw",
      fontWeight: "bold",
      "&:focus, &:hover": {
        borderColor: "#7C27BE"
      }
    }
  })
);
