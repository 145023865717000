import { Box } from "@mui/material";
import { SLIDES } from "constants/brochure";
import { IBrochureImagesProps } from "models/Brochure";
import { ICarConfigProps } from "models/WebGLConfigurator";
import React from "react";
import { StyledSlideContainer, StyledSlideSideLabel } from "./styles";

interface IProps {
  bgSrc: IBrochureImagesProps["rimsImageURL"];
  rims: ICarConfigProps["rims"];
}

const RimsSlide = ({ bgSrc, rims }: IProps) => (
  <>
    <StyledSlideContainer
      bgSrc={bgSrc}
      count={SLIDES.RIMS.count}
      type={SLIDES.RIMS.type}
    />
    <StyledSlideSideLabel side="left" count={SLIDES.RIMS.count}>
      <Box className="title">Rims</Box>
      <Box className="subtitle">{rims?.label}</Box>
      <Box>{rims?.subLabel}</Box>
    </StyledSlideSideLabel>
  </>
);

export default RimsSlide;
