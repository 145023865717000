import { Box, Button, Container, Fab, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledCloseButton = styled(Fab)({
  position: "absolute",
  top: 16,
  right: 16,
  width: 24,
  height: 24,
  minWidth: 24,
  minHeight: 24,
  backgroundColor: "transparent",
  border: "1px solid #7C27BE",
  svg: { color: "#7C27BE", width: 18, height: 18 },
  "&:hover": {
    svg: { color: "white" }
  }
});

export const StyledToggledViewContainer = styled(Container)({
  height: "100vh",
  width: "100vw",
  overflow: "hidden",
  padding: "16vh 10vw 18vh 10vw"
});

export const StyledToggledViewContent = styled(Box)({
  height: "fit-content",
  width: "100%",
  display: "flex"
});

export const StyledToggledViewHeader = styled(Box)({
  textTransform: "uppercase",
  height: "fit-content",
  borderLeft: "5px solid #4400B3",
  h2: {
    color: "#4400B3",
    paddingLeft: "1.25vw",
    paddingTop: "0.5vw",
    fontSize: "2vw",
    margin: 0,
    lineHeight: 1.2,
    "&.topHeader": {
      fontWeight: "400"
    }
  }
});

export const StyledToggledViewPhotoButton = styled(Button)({
  position: "absolute",
  bottom: "2.5vh",
  right: "5vw",
  padding: "1vh 1vw",
  color: "white",
  background: "#7C27BE",
  borderRadius: "4vh",
  fontSize: "0.7vw",
  textTransform: "none",
  opacity: 0.75,
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n" +
    "    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n" +
    "    sans-serif",
  "&:hover": {
    color: "#7C27BE",
    background: "white"
  }
});

export const StyledToggledViewImageContainer = styled(Box)({
  position: "relative",
  flex: 1.5,
  padding: "2vh 3.75vw 0 0"
});

export const StyledToggledViewTextContainer = styled(Stack)({
  flex: 1,
  lineHeight: 1.5,
  paddingTop: "2vh",
  fontSize: "0.75vw",
  ".actionContainer": {
    marginTop: "4vh!important"
  }
});
