export const SLIDES = {
  MAIN: {
    count: 0,
    type: "main"
  },
  GALLERY: {
    count: 1,
    type: "gallery"
  },
  INTERIOR: {
    count: 2,
    type: "interior"
  },
  RIMS: {
    count: 3,
    type: "rims"
  },
  FRONT_VIEW: {
    count: 4,
    type: "frontView"
  }
};
