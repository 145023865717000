import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IMediaProps, initialState } from "models/Media";

export const MediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    setImages: (state, action: PayloadAction<IMediaProps>) => ({
      ...state,
      ...action.payload
    }),
    setVideo: (state, action: PayloadAction<string>) => ({
      ...state,
      videoURL: action.payload
    }),
    resetMedia: () => initialState
  }
});

export const { setImages, setVideo, resetMedia } = MediaSlice.actions;

export default MediaSlice.reducer;
