import { Box, CircularProgress } from "@mui/material";
import { IMediaProps } from "models/Media";
import React from "react";
import {
  StyledImageButton,
  StyledImageContainer,
  StyledImageText
} from "./styles";

interface IProps {
  imgUrl: IMediaProps["advertImageURL"];
  isImageLoaded: boolean;
  onImageLoad: () => void;
  onNavigate: () => void;
}

const ImageArticle = ({
  isImageLoaded,
  onNavigate,
  imgUrl,
  onImageLoad
}: IProps) => (
  <StyledImageContainer>
    {isImageLoaded && (
      <>
        <StyledImageText>
          <h2 className="mainHeader">THE ALL-NEW</h2>
          <h2>SUPER GT 900</h2>
        </StyledImageText>
        <StyledImageButton variant="contained" onClick={onNavigate}>
          Learn more
        </StyledImageButton>
      </>
    )}
    {imgUrl ? (
      <img
        alt="car"
        src={imgUrl}
        width={"100%"}
        height={"100%"}
        onLoad={onImageLoad}
      />
    ) : (
      <Box className="loaderContainer">
        <CircularProgress color="secondary" size={"6vw"} />
      </Box>
    )}
  </StyledImageContainer>
);
export default ImageArticle;
