import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const StyledMenu = styled(Box)({
  display: "flex",
  width: "fit-content",
  flexDirection: "column",
  padding: "0 0.5vw",
  alignItems: "center",
  overflow: "visible"
});

export const StyledMenuItem = styled("li")({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "visible",
  width: "4vw",
  height: "4vw",
  borderRadius: "100%",
  background:
    "linear-gradient(134.21deg, rgba(255, 255, 255, 0.18) 1.34%, rgba(255, 255, 255, 0.06) 100%)",
  boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.1)",
  margin: "1.3vw 0",
  "&:hover, &.active": {
    background:
      "linear-gradient(134.21deg, rgba(255, 255, 255, 0.48) 1.34%, rgba(255, 255, 255, 0.36) 100%)",
    svg: { g: { opacity: 1 }, path: { opacity: 1 } }
  }
});

export const StyledMenuButton = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "none",
  background: "transparent",
  color: "white",
  padding: 0,
  cursor: "pointer",
  width: "100%",
  height: "100%",
  borderRadius: "100%"
});
