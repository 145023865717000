import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const StyledStepperContainer = styled(Box)({
  position: "absolute",
  right: "3vw",
  top: "50vh",
  zIndex: 12,
  "&.initial": {
    transform: "translateY(-50%)",
    transition: "transform  1s linear"
  },
  "&.configMode": {
    transform: "translateY(-15vw)",
    transition: "transform  1s linear"
  },
  ".MuiStepLabel-label": {
    color: "white",
    "&.Mui-active": {
      color: "white",
      fontWeight: "bold"
    }
  }
});

export const StyledConnector = styled(Box)(
  ({ lineHeight }: { lineHeight: number }) => ({
    width: 20,
    justifyContent: "center",
    display: "flex",
    overflow: "hidden",
    height: lineHeight,
    "&.close": {
      height: 0,
      transition: "height 1s"
    }
  })
);

export const StyledContent = styled(Box)(
  ({ lineHeight }: { lineHeight: number }) => ({
    marginLeft: "1vw",
    width: "4vw",
    justifyContent: "center",
    display: "flex",
    height: lineHeight,
    "&.close": {
      height: 0,
      transition: "height 1s"
    }
  })
);

export const StyledLabel = styled(Box)({
  paddingRight: "3vw",
  "&:not(.active)": {
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.1)translate(4%, 4%)",
      transition: "transform .25s",
      svg: {
        circle: {
          fill: "white"
        }
      }
    }
  }
});
