import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const StyledSummaryContainer = styled(Box)({
  width: "100vw",
  height: "100vh",
  video: { 
    position: "absolute",
    width: "100vw",
    minHeight: "100vh",
    objectFit: "cover",
    transform: "translateY(-20%)",
  },
  ".loader": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
});

export const StyledSummaryInfoContainer = styled(Box)({
  position: "absolute",
  padding: "20vh 10vw 18vh 10vw",
  color: "white"
});

export const StyledSummaryInfoHeader = styled(Box)({
  borderLeft: "6px solid white",
  paddingLeft: "2vw",
  h1: {
    lineHeight: 1,
    margin: 0,
    fontSize: "2vw",
    color: "white",
    fontFamily: "GraphikBold",
    "&.mainHeader": { fontFamily: "Graphik" }
  }
});
