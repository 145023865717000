import { Button, InputBase, Stack } from "@mui/material";
import React from "react";
import { emailFormFields } from "./config";
import {
  StyledEmailFormInputContainer,
  StyledFieldsContainer,
  StyledFormContainer
} from "./styles";

interface IProps {
  error: string;
  formValues: { [key: string]: string };
  onChange: (value: { [key: string]: string }) => void;
  onSubmit: () => void;
}

const Component = ({ error, formValues, onChange, onSubmit }: IProps) => (
  <StyledFormContainer>
    <Stack spacing={3}>
      {emailFormFields.map((fieldGroup, index) => (
        <StyledFieldsContainer direction="row" flex={1} spacing={2}>
          {fieldGroup.map(field => (
            <StyledEmailFormInputContainer
              hasError={field.id === "email" && !!error}
              cut={field.id === "email"}
            >
              <label>{field.label}</label>
              <InputBase
                placeholder={field.placeholder}
                size="small"
                value={formValues[field.id] || ""}
                fullWidth
                onChange={e =>
                  onChange({
                    [field.id]: e.target.value
                  })
                }
              />
              {field.id === "email" && !!error && (
                <span className="errorText">{error}</span>
              )}
            </StyledEmailFormInputContainer>
          ))}
          {index === 1 && (
            <Button variant="contained" onClick={onSubmit}>
              Send
            </Button>
          )}
        </StyledFieldsContainer>
      ))}
    </Stack>
  </StyledFormContainer>
);

export default Component;
