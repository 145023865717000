import { ICarPropertiesProps } from "models/Landing";

export const carProps: ICarPropertiesProps[] = [
  {
    percent: 90,
    title: "Top speed mph",
    value: 208
  },
  {
    percent: 80,
    title: "Power bhp",
    value: 630
  },
  {
    percent: 60,
    title: "0 - 62 mph s",
    value: 3.7
  }
];
