import {
  AppConfig,
  CarModel,
  CurrentCarConfigState
} from "contexts/ConfigContext";
import { BaseResolver, Core } from "mv-webgl-core";
import { ActionItem } from "mv-webgl-core/models/action-item";
import {
  MVStartRenderOptions,
  MVStopRenderOptions
} from "mv-webgl-core/models/camera";
import { MVEntity } from "mv-webgl-core/models/entity/mv-entity";
import { Dispatch, SetStateAction, useState } from "react";
import { filter } from "rxjs/operators";

interface WebGLStore {
  core: Core | null;
  webGLViewerRef: HTMLDivElement | null;
  assetsBaseUrl: string | null;
  carModel: CarModel | null;
  onLoadingDone?: Function;
  productEntity: MVEntity | null;
  useCurrentCarConfig:
    | [
        CurrentCarConfigState[],
        Dispatch<SetStateAction<CurrentCarConfigState[]>>
      ]
    | null;
  cameraFlightIsPlaying: boolean;
}

const initialState: WebGLStore = {
  core: null,
  webGLViewerRef: null,
  assetsBaseUrl: null,
  carModel: null,
  productEntity: null,
  useCurrentCarConfig: null,
  cameraFlightIsPlaying: false
};

let webGLStore: WebGLStore = { ...initialState };

export const resetStore = () => {
  try {
    webGLStore = { ...webGLStore, productEntity: null };
    webGLStore.core?.destroy();
  } catch (e) {
    console.log(e);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ensureNotNull = (variables: Record<string, any>): void => {
  Object.entries(variables).forEach(([key, value]) => {
    if (value === null) {
      throw new Error(`Cannot access WebGL "${key}" before initialization`);
    }
  });
};

export interface LoadingScreen {
  displayLoadingUI: () => void;
  hideLoadingUI: () => void;
  loadingUIBackgroundColor?: string;
  loadingUIText?: string;
}

type CustomLoadingScreenOptions = {
  onLoadingStart?: Function;
  onLoadingDone?: Function;
  loadingUIText?: string;
  loadingUIBackgroundColor?: string;
};

class CustomLoadingScreen implements LoadingScreen {
  private onLoadingStart?: Function;
  private onLoadingDone?: Function;
  public loadingUIText?: string;
  public loadingUIBackgroundColor?: string;

  constructor({
    onLoadingStart,
    onLoadingDone,
    loadingUIText,
    loadingUIBackgroundColor
  }: CustomLoadingScreenOptions) {
    this.onLoadingStart = onLoadingStart;
    this.onLoadingDone = onLoadingDone;
    this.loadingUIText = loadingUIText;
    this.loadingUIBackgroundColor = loadingUIBackgroundColor;
  }

  public displayLoadingUI(): void {
    if (this.onLoadingStart) this.onLoadingStart();
  }

  public hideLoadingUI(): void {
    if (this.onLoadingDone) this.onLoadingDone();
  }
}

async function wait(ms: number | undefined) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

async function loadEnvironment(
  name: string,
  configCode: string,
  startRenderOptions?: MVStartRenderOptions
): Promise<void> {
  const { core, carModel } = webGLStore;
  ensureNotNull({ core, carModel });

  const environment = carModel!.environments.find(env => env.name === name);

  if (environment === undefined) {
    throw new Error(`Couldn't find environment with name "${name}"!`);
  }

  await core!.stopRender();
  await core!.Environment.loadEnvironment(
    environment.desktopEnvironmentFile,
    configCode
  );
  await wait(300);
  await core!.startRender(startRenderOptions);
}

async function loadCar(
  configCodes: string[],
  startRenderOptions?: MVStartRenderOptions
): Promise<void> {
  const { core, carModel } = webGLStore;
  ensureNotNull({ core, carModel });

  let stopRenderOptions: MVStopRenderOptions = {};
  if (webGLStore.cameraFlightIsPlaying) {
    startRenderOptions = {
      fadeOutPreviousFrame: false
    };
    stopRenderOptions.preventScreenshotCreation = true;
  }

  await core!.stopRender(stopRenderOptions);
  if (!webGLStore.productEntity) {
    webGLStore.productEntity = await core!.Product.loadProduct(
      carModel!.entityConfigFile,
      configCodes
    );
  } else {
    webGLStore.productEntity = await core!.Product.updateConfiguration(
      carModel!.modelId,
      configCodes
    );
  }
  await wait(300);
  await core!.startRender(startRenderOptions);
}

async function loadCamera(name: string): Promise<void> {
  const { core, carModel } = webGLStore;
  ensureNotNull({ core, carModel });

  const camera = carModel!.cameraShots.find(cam => cam.name === name);

  if (camera === undefined) {
    throw new Error(`Couldn't find camera with name "${name}"!`);
  }

  webGLStore.cameraFlightIsPlaying = name == "Flight View";

  // core!.stopRender();
  await core!.Camera.requestCameraShot(camera.id);
  // core!.startRender();
}

async function initializeCameras(): Promise<void> {
  const { core, carModel } = webGLStore;
  ensureNotNull({ core, carModel });

  await core!.Camera.loadCameraShots(
    carModel!.cameraShots.map(camera => camera.file)
  );
  const defaultCamera = carModel!.cameraShots.find(
    camera => camera.default === true
  );
  if (defaultCamera === undefined) {
    throw new Error(`Couldn't find a default camera shot!
    Add property "default": true to the default entry in "cameraShots" in the config.`);
  }

  await loadCamera(defaultCamera.name);
}

async function actionItemClickHandler(actionItem: ActionItem): Promise<void> {
  const actionItemConfig = webGLStore.carModel?.actionItems.find(
    ai => ai.code === actionItem.id
  );

  if (!actionItemConfig) {
    throw new Error(
      `Couldn't find an action item with the code "${actionItem.id}" in the config file!`
    );
  }
  const actionItemOptions = actionItem.getOptions();

  if (actionItemOptions.cameraId) {
    await webGLStore.core!.Camera.requestCameraShot(actionItemOptions.cameraId);
  }

  if (actionItemConfig.type === "animation") {
    // play animation
    actionItem.block();
    actionItem.playFadeAnimation();

    const to = actionItem.getNextState().animationFrame;
    await webGLStore.core!.Animation.play(actionItem.id, undefined, { to: to });

    actionItem.nextState();
    actionItem.playFadeAnimation();
    actionItem.unblock();
  } else if (actionItemConfig.type === "toggleCarConfigs") {
    // toggle car configs of the specific config property
    if (!webGLStore.useCurrentCarConfig) {
      throw new Error(
        "Cannot trigger action item before car config store is initialized!"
      );
    }
    if (!actionItemConfig.toggleConfigProperty) {
      throw new Error(
        `Action item config of type "${actionItemConfig.type}" must have the property "toggleConfigProperty" set!`
      );
    }

    const [
      currentCarConfig,
      setCurrentCarConfig
    ] = webGLStore.useCurrentCarConfig;

    const toggleConfigCodes = webGLStore.carModel?.carConfigs.filter(
      ({ configProperty }) =>
        configProperty === actionItemConfig.toggleConfigProperty
    );

    if (!toggleConfigCodes) {
      throw new Error(
        `Couldn't find car configs with config property "${actionItemConfig.toggleConfigProperty}"!`
      );
    }

    setCurrentCarConfig(
      currentCarConfig.map(ccc => {
        if (
          toggleConfigCodes
            .map(({ configCode }) => configCode)
            .includes(ccc.carConfig.configCode)
        ) {
          const currentIndex = toggleConfigCodes.findIndex(
            tcc => tcc.configCode === ccc.carConfig.configCode
          );
          ccc.carConfig =
            toggleConfigCodes[(currentIndex + 1) % toggleConfigCodes.length];
        }
        return ccc;
      })
    );

    await loadCar(
      currentCarConfig.map(({ carConfig }) => carConfig.configCode)
    );
  }
}

async function setupApplication(): Promise<void> {
  const { webGLViewerRef, assetsBaseUrl, carModel, onLoadingDone } = webGLStore;
  ensureNotNull({ webGLViewerRef, assetsBaseUrl, carModel });

  const localResolver = new BaseResolver();

  webGLStore.core = new Core(
    webGLViewerRef!,
    (): BaseResolver => localResolver,
    {
      assetsBaseUrl: assetsBaseUrl!,
      openInspectorWithKey: "KeyI",
      antiAliasingSettings: {
        fxaaEnabled: true,
        samplesOnRotation: 1,
        samplesOnStill: 8
      }
    }
  );

  if (onLoadingDone) {
    // this is a workaround until the "applyLoadingUiEventHandlers" function is available on the core
    // as soon as the "applyLoadingUiEventHandlers" function is available uncomment following line:
    // core.applyLoadingUiEventHandlers({ onLoadingDone });
    // and remove this workaround (as well as the "CustomLoadingScreen" class above):
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (webGLStore.core as any)._engine.loadingScreen = new CustomLoadingScreen({
      onLoadingDone
    });
  }

  await webGLStore.core.ActionItem.load(carModel!.actionItemsFile);

  webGLStore.core.ActionItem.onPick$()
    // there's a well known bug with rxjs which cannot (easily) be solved right now
    .pipe(
      // @ts-ignore
      filter(p => carModel!.actionItems.map(item => item.code).includes(p.id))
    )
    .subscribe(actionItemClickHandler);
}

async function launchWebGL(
  webGLViewerRef: HTMLDivElement,
  carConfigState: { [x: string]: any }
): Promise<void> {
  webGLStore.webGLViewerRef = webGLViewerRef;

  await setupApplication();

  const { core, carModel } = webGLStore;
  ensureNotNull({ core, carModel });
  webGLStore.core!.displayDefaultLoadingUi();
  const defaultEnvironment = carModel!.environments.find(
    env => env.default === true
  );

  if (defaultEnvironment === undefined) {
    throw new Error(`Couldn't find a default environment!
    Add property "default": true to the default entry in "environments" in the config.`);
  }
  const defaultEnvironmentConfigCode = carModel!.environmentConfigCodes.find(
    cc => cc.default === true
  );

  if (defaultEnvironmentConfigCode === undefined) {
    throw new Error(`Couldn't find a default environment config code!
    Add property "default": true to the default entry in "environmentConfigCodes" in the config.`);
  }

  const loadEnvironmentPromise = loadEnvironment(
    defaultEnvironment.name,
    defaultEnvironmentConfigCode.code
  );
  let defaultCarConfigurations = carModel!.carConfigs
    .filter(carConfig => carConfig.default === true)
    .map(config =>
      carConfigState[config.configProperty]
        ? carConfigState[config.configProperty]
        : config
    );
  const defaultConfigCodes = defaultCarConfigurations.map(
    carConfig => carConfig.configCode
  );
  const loadCarPromise = loadCar(defaultConfigCodes);

  const initCameraPromise = initializeCameras();

  await Promise.all([
    loadEnvironmentPromise,
    loadCarPromise,
    initCameraPromise
  ]);

  await core!.startRender();
}

export type UseCurrentCarConfigReturnType = Exclude<
  WebGLStore["useCurrentCarConfig"],
  null
>;

function useCurrentCarConfig(): UseCurrentCarConfigReturnType {
  webGLStore.useCurrentCarConfig = useState<CurrentCarConfigState[]>([]);
  return webGLStore.useCurrentCarConfig;
}

export const useWebGL = (
  webGLConfig: AppConfig["webGLConfigurator"]["webGL"],
  onLoadingDone?: Function
): {
  launchWebGL: (
    webGLViewerRef: HTMLDivElement,
    carConfig: any
  ) => Promise<void>;
  loadEnvironment: (
    name: string,
    configCode: string,
    startRenderOptions?: MVStartRenderOptions
  ) => Promise<void>;
  loadCar: (
    configCodes: string[],
    startRenderOptions?: MVStartRenderOptions
  ) => Promise<void>;
  loadCamera: (name: string) => Promise<void>;
  useCurrentCarConfig: () => UseCurrentCarConfigReturnType;
} => {
  let carModel = webGLConfig.carModels.find(
    model => model.modelId === webGLConfig.defaultCarModelId
  );

  if (carModel === undefined) {
    carModel = webGLConfig.carModels[0];
    console.error(`Couldn't find default car model "${webGLConfig.defaultCarModelId}"!
    Will fallback to "${carModel.modelId}"`);
  }

  webGLStore.carModel = carModel;
  webGLStore.assetsBaseUrl = webGLConfig.assetsBaseUrl;
  webGLStore.onLoadingDone = onLoadingDone;

  return {
    launchWebGL,
    loadEnvironment,
    loadCar,
    loadCamera,
    useCurrentCarConfig
  };
};
