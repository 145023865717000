import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog } from "@mui/material";
import VideoPlayer from "components/Videoplayer";
import { ToggledViewConfigModel } from "models/ToggledView";
import React, { ReactNode } from "react";
import {
  StyledCloseButton,
  StyledToggledViewContainer,
  StyledToggledViewContent,
  StyledToggledViewHeader,
  StyledToggledViewImageContainer,
  StyledToggledViewPhotoButton,
  StyledToggledViewTextContainer
} from "./styles";

interface IProps {
  actionComponent?: ReactNode;
  body: ToggledViewConfigModel["body"];
  subTitle: ToggledViewConfigModel["subTitle"];
  title: ToggledViewConfigModel["title"];
  imgSrc: ToggledViewConfigModel["imgSrc"];
  videoSrc: ToggledViewConfigModel["videoSrc"];
  isVideoOpen: boolean;
  onOpenCloseVideo: () => void;
}
const ToggledView = ({
  actionComponent,
  body,
  subTitle,
  title,
  imgSrc,
  videoSrc,
  isVideoOpen,
  onOpenCloseVideo
}: IProps) => (
  <StyledToggledViewContainer maxWidth={false} disableGutters={true}>
    <StyledToggledViewHeader>
      <h2 className="topHeader">{title}</h2>
      <h2>{subTitle}</h2>
    </StyledToggledViewHeader>
    <StyledToggledViewContent>
      <StyledToggledViewImageContainer>
        <img alt="car" src={imgSrc} width="100%" height="100%" />
        <StyledToggledViewPhotoButton onClick={onOpenCloseVideo}>
          Watch video
        </StyledToggledViewPhotoButton>
      </StyledToggledViewImageContainer>
      <StyledToggledViewTextContainer spacing={"2vh"}>
        {body.map(item => (
          <Box>{item}</Box>
        ))}
        <Box className="actionContainer">{actionComponent}</Box>
      </StyledToggledViewTextContainer>
    </StyledToggledViewContent>
    <Dialog open={isVideoOpen} fullWidth maxWidth="xl">
      <VideoPlayer
        posterSrc={imgSrc}
        forceStop={!isVideoOpen}
        videoSrc={videoSrc}
      />
      <StyledCloseButton color="secondary" onClick={onOpenCloseVideo}>
        <CloseIcon />
      </StyledCloseButton>
    </Dialog>
  </StyledToggledViewContainer>
);

export default ToggledView;
