import { Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import { IPropProps, IWelcomeScreenContainerProps } from "models/Landing";

export const StyledCustomizeButton = styled(Button)({
  margin: "2vh auto 0 0",
  color: "black",
  borderRadius: 40,
  fontFamily: "GraphikBold",
  fontSize: "0.75vw",
  textTransform: "none",
  background:
    "linear-gradient(133.77deg, rgba(229, 244, 250, 0.6) 23.29%, rgba(229, 244, 250, 0.2) 74.46%)",
  border: "1px solid rgba(255, 255, 255, 0.7)",
  boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(10px)",
  "&:hover": {
    background:
      "linear-gradient(133.77deg, rgba(229, 244, 250, 0.8) 23.29%, rgba(229, 244, 250, 0.4) 74.46%)"
  }
});

export const StyledMainInfoContainer = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  display: "flex",
  flexDirection: "column",
  height: "fit-content",
  flex: 1,
  padding: "20vh 0 0 10vw"
});

export const StyledMainInfoContent = styled(Box)({
  marginTop: "2vh",
  color: "white",
  fontSize: "0.8vw"
});

export const StyledMainInfoHeader = styled(Box)({
  borderLeft: "6px solid white",
  paddingLeft: "2vw",
  h1: {
    lineHeight: 1,
    margin: 0,
    fontSize: "3vw",
    color: "white",
    fontFamily: "GraphikBold",
    "&.mainHeader": { fontFamily: "Graphik" }
  }
});

export const StyledProp = styled(Box)(({ percent }: IPropProps) => ({
  display: "flex",
  alignItems: "flex-end",
  margin: "2vh 0",
  width: "10vw",
  ".rate": {
    marginRight: "0.5vw",
    width: "75%"
  },
  ".header": {
    fontSize: "0.6vw",
    color: "white",
    marginBottom: "0.5vh"
  },
  ".rateContainer": {
    height: "0.5vh",
    backgroundColor: "black",
    ".ratePoint": {
      height: "100%",
      backgroundColor: "white",
      width: `${percent}%`
    }
  },
  ".valueBlock": {
    color: "white",
    lineHeight: 1,
    fontWeight: "600",
    fontSize: "0.75vw"
  }
}));

export const StyledPropsContainer = styled(Box)({
  marginTop: "2vh",
  display: "flex",
  flexDirection: "column"
});

export const StyledWelcomeScreenContainer = styled(Box)(
  ({ imgSrc }: IWelcomeScreenContainerProps) => ({
    width: "100vw",
    height: "100vh",
    backgroundImage: `url(${imgSrc})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    overflow: "hidden",
    justifyContent: "space-between"
  })
);
