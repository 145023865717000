import { IMAGE_CAR_COLORS, IMAGE_CAR_INTERIOR_COLORS, IMAGE_CAR_RIMS } from "../constants/images";

export interface IWebGLConfiguratorProps {
  carConfig: ICarConfigProps;
  isLoading: boolean;
}

export interface ICarConfigProps {
  carpaint: {
    label: string;
    subLabel: string;
    urlValue:
      | IMAGE_CAR_COLORS.BLACK
      | IMAGE_CAR_COLORS.RED
      | IMAGE_CAR_COLORS.YELLOW;
  } | null;
  rims: {
    label: string;
    subLabel: string;
    urlValue:
      | IMAGE_CAR_RIMS.SPORTS_CAR_BASIS
      | IMAGE_CAR_RIMS.SEDAN_RIM_MV_TIRE;
  } | null;
  interior: { label: string; subLabel: string; urlValue: IMAGE_CAR_INTERIOR_COLORS } | null;
}

export interface  IUnrealCarConfig {
  carpaint: IMAGE_CAR_COLORS.BLACK  | IMAGE_CAR_COLORS.RED | IMAGE_CAR_COLORS.YELLOW;
  rims: IMAGE_CAR_RIMS.SPORTS_CAR_BASIS | IMAGE_CAR_RIMS.SEDAN_RIM_MV_TIRE;
  interior: IMAGE_CAR_INTERIOR_COLORS;
}

export const initialState: IWebGLConfiguratorProps = {
  carConfig: { carpaint: null, rims: null, interior: null },
  isLoading: false
};
