import { useWebGL } from "components/WebGLConfigurator/WebGL";
import { CarModel } from "contexts/ConfigContext";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  loadConfigurator,
  loadConfiguratorSuccess
} from "store/reducers/WebGLConfigurator";
import { useSVGSizes } from "utils/resizeCustomHook";
import Component from "./component";

const config = require(`config/mackevision-webgl-configurator.json`);

interface IProps {
  activeStep: number;
  setActiveStep: (step: number) => void;
}

const LandingStepper = ({ activeStep, setActiveStep }: IProps) => {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  const { stepper } = useSVGSizes();

  const onLoadingDone = useCallback(() => {
    setIsLoaded(true);
    dispatch(loadConfiguratorSuccess());
  }, [isLoaded]);

  useEffect(() => {
    if (!isLoaded) dispatch(loadConfigurator());
  }, [isLoaded]);

  const {
    loadEnvironment,
    loadCamera,
    loadCar,
    useCurrentCarConfig
  } = useWebGL(config.webGL, onLoadingDone);
  const [carModel, _] = useState<CarModel>(config.webGL.carModels[0]);
  return (
    <Component
      activeStep={activeStep}
      carModel={carModel}
      loadEnvironment={loadEnvironment}
      loadCamera={loadCamera}
      loadCar={loadCar}
      setActiveStep={setActiveStep}
      stepper={stepper}
      useCurrentCarConfig={useCurrentCarConfig}
    />
  );
};

export default LandingStepper;
