import CarpaintSVG from "assets/UIMenu/Carpaint.svg";
import InteriorSVG from "assets/UIMenu/Interiror.svg";
import RimsSVG from "assets/UIMenu/Rims.svg";
import UISubmenuItems from "components/WebglControls/UISubmenuItems";
import React from "react";
import { StyledMenu, StyledMenuButton, StyledMenuItem } from "./styles";

interface IProps {
  availableOptions: any;
  currentEditable: string;
  currentCarConfig: any;
  handleSelectOption: (id: string, label: string) => void;
  handleConfigChange: (option: any) => void;
}

const UIMenu = ({
  availableOptions,
  currentEditable,
  currentCarConfig,
  handleSelectOption,
  handleConfigChange
}: IProps) => (
  <StyledMenu>
    {currentCarConfig.map(
      (item: { id: string; label: string }, index: number) =>
        item.id !== "lights" && (
          <StyledMenuItem
            key={item.id}
            className={item.id === currentEditable ? "active" : ""}
          >
            <StyledMenuButton
              onClick={() => {
                handleSelectOption(item.id, item.label);
              }}
            >
              {index === 0 && (
                <CarpaintSVG width={"3vw"} height={"3vw"} viewBox="0 0 65 65" />
              )}
              {index === 1 && (
                <RimsSVG width={"3vw"} height={"3vw"} viewBox="0 0 65 65" />
              )}
              {index === 2 && (
                <InteriorSVG width={"3vw"} height={"3vw"} viewBox="0 0 65 65" />
              )}
            </StyledMenuButton>
            <div
              style={{
                position: "absolute",
                bottom: "-1.5vw",
                color: "white",
                fontFamily: "Graphik",
                pointerEvents: "none"
              }}
            >
              {item.label.replace("Wheels", "Rims")}
            </div>
            {item.id === currentEditable && (
              <UISubmenuItems
                availableOptions={availableOptions}
                config={currentCarConfig}
                currentEditable={currentEditable}
                changeVariant={handleConfigChange}
              />
            )}
          </StyledMenuItem>
        )
    )}
  </StyledMenu>
);

export default UIMenu;
