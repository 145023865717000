import bg4Src from "assets/Landing/PreviewScreen/bg4.png";
import { IConfigProps, IPreviewConfigProps } from "models/Landing";

export const getPreviewConfig = ({
  carpaintImgSrc,
  rimsImgSrc,
  interiorImgSrc
}: IPreviewConfigProps): IConfigProps[] => [
  { imgSrc: carpaintImgSrc, title: "Color" },
  { imgSrc: rimsImgSrc, title: "Rims", rightAligned: true },
  { imgSrc: interiorImgSrc, title: "Interior" },
  { imgSrc: bg4Src, title: "" }
];
