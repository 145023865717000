import WebGLViewer from "components/WebGLConfigurator";
import { resetStore } from "components/WebGLConfigurator/WebGL";
import React, { useEffect } from "react";
import {
  StyledMainInfoContainer,
  StyledMainInfoHeader
} from "../WelcomeScreen/styles";
import { StyledConfiguratorScreenContainer } from "./styles";

const ConfiguratorScreen = () => {
  useEffect(
    () => () => {
      resetStore(); // reset and destroy configurator on unmount component
    },
    []
  );
  return (
    <StyledConfiguratorScreenContainer>
      <WebGLViewer />
      <StyledMainInfoContainer>
        <StyledMainInfoHeader>
          <h1 className="mainHeader">CUSTOMIZE</h1>
          <h1>YOUR CAR</h1>
        </StyledMainInfoHeader>
      </StyledMainInfoContainer>
    </StyledConfiguratorScreenContainer>
  );
};

export default ConfiguratorScreen;
