import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const StyledMenuContainer = styled(Box)({
  position: "absolute",
  top: 0,
  right: "9rem",
  display: "flex",
  flexDirection: "row",
  overflow: "visible",
  height: "8vh"
});

export const StyledMenuItem = styled(Box)({
  position: "relative",
  width: "100%",
  margin: "0 0.25vw",
  padding: "1vh 0.25vw",
  border: "1px solid transparent",
  borderRadius: "10px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  backgroundColor: "rgba(235, 235, 235, 0.25)",
  img: { width: "2vw", height: "2vw" },
  "&:hover": {
    backgroundColor: "rgba(235, 235, 235, 0.75)"
  },
  "&.selected": {
    backgroundColor: "rgba(235, 235, 235, 0.5)",
    cursor: "inherit"
  }
});

export const StyledBadgeContainer = styled(Box)({
  position: "absolute",
  bottom: "-0.8vh",
  width: "0.8vw",
  height: "0.8vw",
  left: "-0.4vw",
  backgroundColor: "rgba(235, 235, 235, 0.5)",
  borderRadius: "50%",
  svg: {
    width: "0.7vw",
    height: "0.7vw",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }
});
