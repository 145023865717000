import { CircularProgress } from "@mui/material";
import { CarModel } from "contexts/ConfigContext";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  StyledConfiguratorContainer,
  StyledConfiguratorWrapper,
  StyledLoaderContainer
} from "./styles";
import { useWebGL } from "./WebGL";

const WebGLViewer = () => {
  const isConfiguratorLoading = useSelector(
    (state: RootState) => state.webGLConfigurator.isLoading
  );
  let webGLViewerRef = useRef<HTMLDivElement>(null);
  const config = require(`config/mackevision-webgl-configurator.json`);
  const { launchWebGL } = useWebGL(config.webGL);
  const [_, setCarModel] = useState<CarModel>(config.webGL.carModels[0]);
  const carConfigState = useSelector(
    (state: RootState) => state.webGLConfigurator.carConfig
  );

  useEffect(() => {
    if (webGLViewerRef.current) {
      launchWebGL(webGLViewerRef.current, carConfigState);
    }
  }, [webGLViewerRef]);

  useEffect(() => {
    if (config && config.webGL && config.webGL.carModels) {
      const carModel = config.webGL.carModels.find(
        (carModel: { modelId: any }) =>
          carModel.modelId === config.webGL.defaultCarModelId
      );
      if (carModel === undefined) {
        console.error(`Couldn't find default car model "${config.webGL.defaultCarModelId}"!
        Will fallback to "${config.webGL.carModels[0].modelId}"`);
      } else {
        setCarModel(carModel);
      }
    }
  }, [config, setCarModel]);

  return (
    <StyledConfiguratorWrapper>
      <StyledConfiguratorContainer ref={webGLViewerRef} />
      {isConfiguratorLoading && (
        <StyledLoaderContainer>
          <CircularProgress color="secondary" size={120} />
        </StyledLoaderContainer>
      )}
    </StyledConfiguratorWrapper>
  );
};
export default WebGLViewer;
