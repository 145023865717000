import { Box, Stack } from "@mui/material";
import { SLIDES } from "constants/brochure";
import { IBrochureImagesProps } from "models/Brochure";
import React, { useState } from "react";
import { EffectCoverflow, Pagination } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  StyledSlideCenterLabel,
  StyledSlideContainer,
  StyledSlideThumb,
  StyledSwiperContainer
} from "./styles";

interface IProps {
  images: IBrochureImagesProps["detailsImagesURL"];
}

const GallerySlide = ({ images }: IProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  return (
    <>
      <StyledSlideContainer count={SLIDES.GALLERY.count}>
        <StyledSwiperContainer>
          <Swiper
            onSlideChange={slider => setCurrentSlide(slider.realIndex)}
            centeredSlidesBounds
            initialSlide={0}
            modules={[EffectCoverflow, Pagination]}
            effect={"coverflow"}
            coverflowEffect={{
              rotate: 0,
              slideShadows: false,
              scale: 0.75
            }}
            loop
            pagination
            spaceBetween={0}
            centeredSlides
            slidesPerView={3}
          >
            {images?.map(imageSrc => (
              <SwiperSlide>
                <img
                  src={imageSrc}
                  width={"100%"}
                  height={"100%"}
                  alt={"car"}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <Stack
            flex={1}
            direction="row"
            spacing="1vw"
            justifyContent="center"
            alignItems="center"
          >
            {images.map((_, index) => (
              <StyledSlideThumb active={index === currentSlide} />
            ))}
          </Stack>
        </StyledSwiperContainer>
      </StyledSlideContainer>
      <StyledSlideCenterLabel count={SLIDES.GALLERY.count}>
        <Box className="title">
          Your <b>SUPER GT 900</b> Gallery
        </Box>
      </StyledSlideCenterLabel>
    </>
  );
};

export default GallerySlide;
