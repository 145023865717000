import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "models/Layout";

export const viewModeSlice = createSlice({
  name: "viewMode",
  initialState,
  reducers: {
    switchViewMode: state => ({
      animationDirection: state.toggledView ? "out" : "in",
      toggledView: !state.toggledView,
      iFrameMode: false
    }),
    switchIframeMode: state => ({ ...state, iFrameMode: true }),
    resetViewMode: () => initialState
  }
});

export const {
  switchViewMode,
  switchIframeMode,
  resetViewMode
} = viewModeSlice.actions;

export default viewModeSlice.reducer;
