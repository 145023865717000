import { APP_ROUTES } from "constants/routing";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { useSVGSizes } from "utils/resizeCustomHook";

import Component from "./component";

const Brochure = () => {
  const navigate = useNavigate();
  const {
    detailsImagesURL,
    frontViewImageURL,
    interiorImageURL,
    mainScreenImageURL,
    rimsImageURL
  } = useSelector((state: RootState) => state.brochureImages);

  const name = useSelector((state: RootState) => state.bookForm.name) || "Jane";

  const { interior, rims } = useSelector(
    (state: RootState) => state.webGLConfigurator.carConfig
  );

  const { purchaseBtn, purchaseBtnContainer, mask } = useSVGSizes();

  const handleNavigate = () => navigate(APP_ROUTES.SUMMARY);

  return (
    <Component
      detailsImagesSrc={detailsImagesURL}
      frontViewImageSrc={frontViewImageURL}
      interior={interior}
      interiorImageSrc={interiorImageURL}
      mainScreenImageSrc={mainScreenImageURL}
      mask={mask}
      name={name}
      onNavigate={handleNavigate}
      purchaseBtn={purchaseBtn}
      purchaseBtnContainer={purchaseBtnContainer}
      rims={rims}
      rimsImageSrc={rimsImageURL}
    />
  );
};
export default Brochure;
