import { ReactNode } from "react";

export interface IScrollingScreenContainerProps {
  counter: number;
}
// config
export interface ICarPropertiesProps {
  percent: number;
  title: string;
  value: number;
}

// Welcome screen
export interface IWelcomeScreenContainerProps {
  imgSrc: string;
}

export interface IPropProps {
  percent: number;
}

// Landing stepper
export interface IStepperLabelProps {
  isActive: boolean;
}
export interface IStepperSubMenuProps {
  visible: boolean;
}

// Landing stepper config
export interface IStepsProps {
  label: string;
}

// Preview screen
export interface IPreviewBox {
  imgSrc: string;
  rightAligned?: boolean;
}

export interface IConfigProps {
  imgSrc: string;
  title: string;
  rightAligned?: boolean;
}

// Preview screen config
export interface IPreviewConfigProps {
  carpaintImgSrc: string;
  rimsImgSrc: string;
  interiorImgSrc: string;
}

// Preview screen Book form
export interface IBookFormProps {
  readonly name: string;
  readonly surname: string;
  readonly email: string;
  readonly phone: string;
  readonly appointmentDate: string;
  readonly dealerTime: string;
  readonly selectedItem: string;
}

export interface IBookFormFieldsProps {
  readonly id: string;
  readonly label: string;
  readonly placeholder: string;
}

export interface IBookFormExpProps {
  readonly id: string;
  readonly label: string;
  readonly icon: ReactNode;
}

export const bookFormInitialState: IBookFormProps = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  dealerTime: "",
  appointmentDate: "",
  selectedItem: ""
};

export interface ILandingStepperProps {
  activeStep: number;
}

export const landingStepperInitialState: ILandingStepperProps = {
  activeStep: 0
};
