import { APP_ROUTES } from "constants/routing";
import { MousePositionModel } from "models/Home";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Component from "./component";

const Home = () => {
  const navigate = useNavigate();
  const [position, setPosition] = useState<MousePositionModel>({ x: 0, y: 0 });
  const [isPopupOpen, setIsPopupOpen] = useState(true);

  document.onmousemove = (e) => setPosition({ x: e.clientX, y: e.clientY });

  const handleOnEnter = () => navigate(APP_ROUTES.ADVERT);

  const handleOnOpenClosePopup = () => setIsPopupOpen(!isPopupOpen);

  return <Component mousePosition={position} isPopupOpen={isPopupOpen} onEnter={handleOnEnter} onOpenClosePopup={handleOnOpenClosePopup} />;
};

export default Home;
