import { Button } from "@mui/material";
import ArrowLeftSVG from "assets/BottomBar/arrowLeft.svg";
import ArrowRightSVG from "assets/BottomBar/arrowRight.svg";
import TabBtnSVG from "assets/BottomBar/tabBtn.svg";
import { IViewModeProps } from "models/Layout";
import { MenuBarProps } from "models/menu";
import React from "react";
import {
  StyledBottomBarContainer,
  StyledButtonsContainer,
  StyledPurpleButtonContainer
} from "./styles";

interface IProps {
  animationDirection: IViewModeProps["animationDirection"];
  arrowsHeight: number;
  arrowsWidth: number;
  buttonHeight: MenuBarProps["buttonHeight"];
  buttonMarginBottom: MenuBarProps["buttonMarginBottom"];
  buttonWidth: MenuBarProps["buttonWidth"];
  currentMenuSvg: React.ReactNode;
  goNext: () => void;
  goPrev: () => void;
  onSwitchViewMode: () => void;
}

const Component = ({
  animationDirection,
  arrowsHeight,
  arrowsWidth,
  buttonMarginBottom,
  buttonHeight,
  buttonWidth,
  currentMenuSvg,
  goNext,
  goPrev,
  onSwitchViewMode
}: IProps) => (
  <StyledBottomBarContainer>
    <StyledPurpleButtonContainer
      buttonHeight={buttonHeight}
      buttonMarginBottom={buttonMarginBottom}
      buttonWidth={buttonWidth}
      className={
        animationDirection
          ? animationDirection === "out"
            ? "animationOut"
            : "animationIn"
          : "initial"
      }
    >
      <TabBtnSVG viewBox="0 0 534 77" className="barBtn" />
      <Button onClick={onSwitchViewMode}></Button>
    </StyledPurpleButtonContainer>
    {currentMenuSvg}
    <StyledButtonsContainer>
      <Button onClick={goPrev}>
        <ArrowLeftSVG
          viewBox="0 0 18 28"
          width={arrowsWidth}
          height={arrowsHeight}
        />
      </Button>
      <Button onClick={goNext}>
        <ArrowRightSVG
          viewBox="0 0 18 28"
          width={arrowsWidth}
          height={arrowsHeight}
        />
      </Button>
    </StyledButtonsContainer>
  </StyledBottomBarContainer>
);

export default Component;
