import { APP_ROUTES } from "constants/routing";
import { ToggledViewTypeByRoute } from "models/ToggledView";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Location, useLocation } from "react-router-dom";
import { RootState } from "store";
import { setToggledViewType } from "store/reducers/ToggledView";
import { resetViewMode } from "store/reducers/ViewMode";

const useToggledView = () => {
  const location: Location = useLocation();
  const dispatch = useDispatch();

  const isToggledView = useSelector(
    (state: RootState) => state.viewMode.toggledView
  );

  const [toggledView, setToggledView] = useState(isToggledView);

  useEffect(() => {
    if (
      location.pathname === APP_ROUTES.HOME ||
      location.pathname === APP_ROUTES.SUMMARY
    ) {
      dispatch(resetViewMode());
      setToggledView(false);
    }
    dispatch(
      setToggledViewType(
        ToggledViewTypeByRoute[location.pathname as APP_ROUTES]
      )
    );
  }, [location]);

  useEffect(() => {
    setTimeout(() => setToggledView(isToggledView), 500);
  }, [isToggledView]);

  return { toggledView };
};

export default useToggledView;
