import { ReactNode } from "react";

export interface IEmailProps {
  readonly isLoading: boolean;
}

export const initialState: IEmailProps = {
  isLoading: false
};

export interface IEmailMenuItemsProps {
  title: string;
  icon?: ReactNode;
  isHeader?: boolean;
  selected?: boolean;
  label?: string;
}

export interface IEmailLettersProps {
  subject: string;
  body: string[];
  avatarSrc: string;
  time: string;
  selected?: boolean;
}

export interface IEmailColumnProps {
  withBg?: boolean;
  withMargin?: boolean;
}

export interface IEmailItemProps {
  selected?: boolean;
}

export interface IEmailAvatarProps {
  large?: boolean;
}

export interface IEmailMenuProps {
  selected?: boolean;
}
