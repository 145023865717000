import DraftsIconSVG from "assets/Email/Drafts.svg";
import FlaggedIconSVG from "assets/Email/Flagged.svg";
import FolderIconSVG from "assets/Email/Folder.svg";
import InboxIconSVG from "assets/Email/Inbox.svg";
import Profile1SVG from "assets/Email/profile_picture_1.png";
import Profile2SVG from "assets/Email/profile_picture_2.png";
import Profile3SVG from "assets/Email/profile_picture_3.png";
import Profile4SVG from "assets/Email/profile_picture_4.png";
import Profile5SVG from "assets/Email/profile_picture_5.png";
import SentIconSVG from "assets/Email/Sent.svg";
import TrashIconSVG from "assets/Email/Trash.svg";
import { IEmailLettersProps, IEmailMenuItemsProps } from "models/Email";
import React from "react";

const emailBody = [
  "Work Enquiry",
  "Hi! We're so excited to start working together on this project. Please let us know if you need..."
];

export const emailMenuItems: IEmailMenuItemsProps[] = [
  { title: "Inbox", icon: <InboxIconSVG />, selected: true, label: "20" },
  { title: "Flagged", icon: <FlaggedIconSVG /> },
  { title: "Drafts", icon: <DraftsIconSVG /> },
  { title: "Sent", icon: <SentIconSVG /> },
  { title: "Trash", icon: <TrashIconSVG /> },
  { title: "Folders", isHeader: true },
  { title: "Office", icon: <FolderIconSVG /> },
  { title: "Personal", icon: <FolderIconSVG /> },
  { title: "News", icon: <FolderIconSVG /> },
  { title: "Travel", icon: <FolderIconSVG /> }
];

export const emails: IEmailLettersProps[] = [
  {
    subject: "Mark Andrew",
    body: emailBody,
    avatarSrc: Profile5SVG,
    time: "12:35 pm"
  },
  {
    subject: "Your Offer",
    body: [
      "Super GT 900 is waiting for you",
      "The iconic super car GT 900, unites beauty and performance to create a breathtaking..."
    ],
    avatarSrc: "",
    time: "12:10 pm",
    selected: true
  },
  {
    subject: "Andrea Wallace",
    body: emailBody,
    avatarSrc: Profile4SVG,
    time: "12:35 pm"
  },
  {
    subject: "Issa Smith",
    body: emailBody,
    avatarSrc: Profile3SVG,
    time: "11:30 pm"
  },
  {
    subject: "John Q",
    body: emailBody,
    avatarSrc: Profile2SVG,
    time: "11:05 pm"
  },
  {
    subject: "Mari Lewis",
    body: emailBody,
    avatarSrc: Profile1SVG,
    time: "09:45 pm"
  }
];
