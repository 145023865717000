import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { landingStepperInitialState } from "models/Landing";

export const LandingSteppeSlice = createSlice({
  name: "landingStepper",
  initialState: landingStepperInitialState,
  reducers: {
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    resetLandingStepper: () => landingStepperInitialState
  }
});

export const {
  setActiveStep,
  resetLandingStepper
} = LandingSteppeSlice.actions;

export default LandingSteppeSlice.reducer;
