import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { IPreviewBox } from "models/Landing";

export const StyledPreviewBox = styled(Box)(
  ({ imgSrc, rightAligned }: IPreviewBox) => ({
    position: "relative",
    flex: 1,
    backgroundImage: `url(${imgSrc})`,
    backgroundSize: "cover",
    backgroundPosition: `${rightAligned ? "right" : "center"} center`,
    img: {
      display: "none"
    }
  })
);

export const StyledPreviewContainer = styled(Box)({
  height: "100vh",
  width: "100vw",
  display: "flex",
  flexDirection: "row",
  position: "relative"
});

export const StyledPreviewScreenTextContainer = styled(Box)({
  position: "absolute",
  top: "50vh",
  left: "50%",
  transform: "translate(-50%, 100%)",
  width: "75%",
  textAlign: "center",
  color: "white",
  fontSize: "1.25vw",
  fontWeight: "bold",
  button: {
    paddingTop: "1.75vh",
    paddingBottom: "1.75vh",
    marginTop: "2vh",
    backgroundColor: "white",
    border: "1px solid transparent",
    borderRadius: 30,
    fontFamily: "GraphikBold",
    fontWeight: "bold",
    color: "black",
    textTransform: "none",
    "&:hover": {
      color: "white"
    }
  }
});

export const StyledTextContainer = styled(Box)({
  position: "absolute",
  bottom: "25vh",
  left: "50%",
  transform: "translateX(-50%)",
  color: "white",
  fontSize: "1.75vw"
});
