import advertImgSrc from "assets/ToggledView/digital-advertising.png";
import emailImgSrc from "assets/ToggledView/follow-up-email.png";
import landingImgSrc from "assets/ToggledView/langding-page.png";
import appointmentImgSrc from "assets/ToggledView/virtual-sales.png";
import FormComponent from "components/EmailFormComponent";
import { TOGGLED_VIEW_TYPES } from "constants/toggledView";
import { ToggledViewConfigModel } from "models/ToggledView";
import React from "react";
import {
  bannerVideo,
  brochureVideo,
  configuratorVideo,
  metaverseVideo
} from "../../../public/assets/video";

export const getToggledViewConfig = (
  type: TOGGLED_VIEW_TYPES | null
): ToggledViewConfigModel => {
  switch (type) {
    case TOGGLED_VIEW_TYPES.ADVERT:
      return {
        title: "How Next-Gen Content powers",
        subTitle: "Dynamic Creative Optimization",
        imgSrc: advertImgSrc,
        videoSrc: bannerVideo,
        body: [
          "By combining three components, the Dynamic Visual Content Sudio, a Recommendation Engine, and our Master Asset Creator, we enable our clients to have a highly effective and personalized digital marketing system that is continuously optimized and improved.",
          "The Dynamic Visual Content Studio allows us to create a variety of digital assets tailored to our clients' audience individual needs. We use state-of-the-art technologies and tools to produce content of high quality and visually appealing.",
          "The Recommendation Engine ensures that the right assets are served to the right people at the right time. We leverage data analysis and machine learning to understand the interests and behavior patterns of the target audience and select appropriate content accordingly.",
          "The Master Asset Creator is the heart of our solution. Here, all digital assets are managed and optimized centrally. We use standardized templates to automate the final asset compositing to ensure brand consistency on the one side but allow dynamic adaptability to market requirements on the other side."
        ]
      };
    case TOGGLED_VIEW_TYPES.LANDING:
      return {
        title: "How Next-Gen Content powers",
        subTitle: "Personalized Web Content",
        imgSrc: landingImgSrc,
        videoSrc: configuratorVideo,
        body: [
          "Next-Gen Content empowers businesses to create fully customizable web content, perfectly tailored to the unique needs of each individual customer. In today's fast-paced digital landscape, traditional media production falls short due to a lack of necessary automation.",
          "Cloud computing plays a crucial role in this process, allowing for efficient utilization of resources and computational power, enabling fast and scalable services. Real-time rendering takes it one step further, providing the ability to generate and render complex graphics, moving imagery and interactive elements in real-time.",
          "To fully leverage the potential of these technologies, we have adopted a serverless microfrontend approach. This innovative approach provides several distinct advantages over traditional DAM systems. Firstly, it eliminates the need for a centralized repository, which can be both costly and difficult to manage. Secondly, it allows for greater flexibility in content management and delivery, as each component can be tailored to specific use cases.",
          "By utilizing the latest technology and adopting innovative approaches to content creation, businesses can stay ahead of the curve and provide their customers with a truly unique and personalized experience."
        ]
      };
    case TOGGLED_VIEW_TYPES.APPOINTMENT:
      return {
        title: "How Next-Gen Content enriches",
        subTitle: "Micro-/Metaverse Experiences",
        imgSrc: appointmentImgSrc,
        videoSrc: metaverseVideo,
        body: [
          "The key value proposition of Next-Generation Content lies in the principle of single source production. By adopting this principle, businesses can ensure a consistent and high-quality representation of their products and services across all digital channels, including the Metaverse. Being Metaverse-ready entails adhering to this principle.",
          "The Metaverse is poised to become a critical customer touchpoint or even a space that can seamlessly integrate into an existing customer journey. Our solution provides the ability to automatically scale and adapt 3D content to meet the specific requirements of any 3D platform.",
          "To fuel content to a cloud-rendering solution operated by a 3rd-party platform, two essential steps need to be taken. Firstly, it is essential fulfill the necessary requirements of the platform, such as file formats, game engine settings, and performance criteria. Secondly, for a seamless customer experience, it is crucial to pass on certain parameters to the platform and be able to retrieve them from the platform."

        ]
      };
    case TOGGLED_VIEW_TYPES.EMAIL:
      return {
        title: "How Next-Gen Content is supporting",
        subTitle: "Lead- and Customer Relationship Management",
        imgSrc: emailImgSrc,
        videoSrc: brochureVideo,
        body: [
          "Increasing Marketing Automation goes hand in hand with the automation of content production. Providing an unlimited volume of different variations of Marketing Assets, composed by the Master Asset Creator in connection with the Dynamic Visual Content Studio, sets a new standard in operating a self-optimizing and operating marketing system.",
          "No matter it is about creating visual assets for e.g. an individualized header of an email or composing a complete microsite with content of exactly the just configured car, the possibilities are limitless.",
          "A DAM, which normally holds the responsibility to provide the appropriate assets for comparable fields of application gets by-passed in this future-oriented scenario as the marketing assets are getting composed right in the moment there are requested."
        ],
        actionComponent: <FormComponent />
      };
    case TOGGLED_VIEW_TYPES.BROCHURE:
      return {
        title: "How Next-Gen Content is supporting",
        subTitle: "Lead- and Customer Relationship Management",
        imgSrc: emailImgSrc,
        videoSrc: brochureVideo,
        body: [
          "Increasing Marketing Automation goes hand in hand with the automation of content production. Providing an unlimited volume of different variations of Marketing Assets, composed by the Master Asset Creator in connection with the Dynamic Visual Content Studio, sets a new standard in operating a self-optimizing and operating marketing system.",
          "No matter it is about creating visual assets for e.g. an individualized header of an email or composing a complete microsite with content of exactly the just configured car, the possibilities are limitless.",
          "A DAM, which normally holds the responsibility to provide the appropriate assets for comparable fields of application gets by-passed in this future-oriented scenario as the marketing assets are getting composed right in the moment there are requested."
        ]
      };
    default:
      return { title: "", subTitle: "", imgSrc: "", videoSrc: "", body: [] };
  }
};
