import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import emailSaga from "sagas/email";
import mediaSaga from "sagas/media";
import BookForm from "./reducers/BookForm";
import BrochureImages from "./reducers/Brochure";
import LandingStepper from "./reducers/LandingStepper";
import Media from "./reducers/Media";
import ToggledView from "./reducers/ToggledView";
import ViewMode from "./reducers/ViewMode";
import WebGLConfigurator from "./reducers/WebGLConfigurator";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const store = configureStore({
  reducer: {
    bookForm: BookForm,
    brochureImages: BrochureImages,
    media: Media,
    landingStepper: LandingStepper,
    toggledView: ToggledView,
    viewMode: ViewMode,
    webGLConfigurator: WebGLConfigurator
  },
  middleware: middlewares
});

sagaMiddleware.run(mediaSaga);
sagaMiddleware.run(emailSaga);

export type RootState = ReturnType<typeof store.getState>;

export default store;
