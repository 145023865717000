

export interface IEmailPayload {
  email: string;
  name: string;
  background_id: string;
  perspective_id: string;
  rims: string;
  paint: string;
  interior: string;
  click_url: string;
}

export function doSendMail(mail: IEmailPayload) {
  return fetch(
    'https://ngc-api.dynamic-visual-content.com/send_mail',
    {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json"
      }),
      body: JSON.stringify(mail),
      mode: "cors"
    }
  )
}
