import { Box, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledControlsContainer = styled(Stack)({
  position: "absolute",
  bottom: 16,
  left: "50%",
  transform: "translateX(-50%)",
  alignItems: "center",
  width: "calc(100% - 32px)",
  button: {
    width: 24,
    height: 24,
    minWidth: 24,
    minHeight: 24,
    backgroundColor: "transparent",
    border: "1px solid #7C27BE",
    svg: { color: "#7C27BE", width: 18, height: 18 },
    "&:hover": {
      svg: { color: "white" }
    }
  }
});

export const StyledVideoWrapper = styled(Box)({
  overflow: "hidden",
  alignItems: "center",
  justifyContent: "center",
  video: {
    display: "block"
  }
});

export const StyledVolumeController = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  ".slider": {
    position: "absolute",
    top: -116,
    height: 146,
    display: "none",
    div: {
      height: 100,
      marginBottom: "auto"
    }
  },
  "&:hover": {
    ".slider": { display: "flex" }
  }
});
