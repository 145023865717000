import { Button } from "@mui/material";
import PurchaseBtnSVG from "assets/Brochure/purchaseButton.svg";
import { IBrochureImagesProps } from "models/Brochure";
import { IBookFormProps } from "models/Landing";
import { ICarConfigProps } from "models/WebGLConfigurator";
import React from "react";
import FrontViewSlide from "./slides/FrontViewSlide";
import GallerySlide from "./slides/GallerySlide";
import InteriorSlide from "./slides/InteriorSlide";
import MainSlide from "./slides/MainSlide";
import RimsSlide from "./slides/RimsSlide";
import {
  StyledBrochureContainer,
  StyledPurchaseButtonContainer
} from "./styles";

interface IProps {
  detailsImagesSrc: IBrochureImagesProps["detailsImagesURL"];
  frontViewImageSrc: IBrochureImagesProps["frontViewImageURL"];
  interior: ICarConfigProps["interior"];
  interiorImageSrc: IBrochureImagesProps["interiorImageURL"];
  mainScreenImageSrc: IBrochureImagesProps["mainScreenImageURL"];
  mask: { width: number; height: number };
  name: IBookFormProps["name"];
  onNavigate: () => void;
  purchaseBtn: { width: number; height: number; xPos: number; yPos: number };
  purchaseBtnContainer: { width: number; height: number };
  rims: ICarConfigProps["rims"];
  rimsImageSrc: IBrochureImagesProps["rimsImageURL"];
}

const Component = ({
  detailsImagesSrc,
  frontViewImageSrc,
  interior,
  interiorImageSrc,
  mainScreenImageSrc,
  mask,
  name,
  onNavigate,
  purchaseBtn,
  purchaseBtnContainer,
  rims,
  rimsImageSrc
}: IProps) => (
  <StyledBrochureContainer>
    <MainSlide bgSrc={mainScreenImageSrc} name={name} />
    <GallerySlide images={detailsImagesSrc} />
    <InteriorSlide bgSrc={interiorImageSrc} interior={interior} />
    <RimsSlide bgSrc={rimsImageSrc} rims={rims} />
    <FrontViewSlide bgSrc={frontViewImageSrc} mask={mask} />

    <StyledPurchaseButtonContainer purchaseBtn={purchaseBtn}>
      <PurchaseBtnSVG
        viewBox="0 0 543 134"
        height={purchaseBtnContainer.height}
        width={purchaseBtnContainer.width}
      />
      <Button onClick={onNavigate} />
    </StyledPurchaseButtonContainer>
  </StyledBrochureContainer>
);

export default Component;
