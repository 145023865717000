import { Avatar, Box, Stack } from "@mui/material";
import { styled } from "@mui/system";
import {
  IEmailAvatarProps,
  IEmailColumnProps,
  IEmailItemProps,
  IEmailMenuProps
} from "models/Email";

export const StyledEmailBadge = styled(Box)({
  position: "absolute",
  top: 0,
  right: 0
});

export const StyledEmailColumn = styled(Box)(
  ({ withBg, withMargin }: IEmailColumnProps) => ({
    height: "100vh",
    padding: "12vh 0",
    margin: withMargin ? "0 1.5vw" : 0,
    boxShadow: withBg ? "0 25px 50px -12px rgba(0, 0, 0, 0.25)" : "",
    h2: {
      fontSize: "1.25vw",
      paddingBottom: "1.5vh"
    },
    display: "flex",
    flexDirection: "column"
  })
);

export const StyledEmailContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  height: "100vh",
  overflow: "hidden",
  padding: "0 10vw 12vh 10vw"
});

export const StyledEmailItem = styled(Box)(({ selected }: IEmailItemProps) => ({
  display: "flex",
  flexDirection: "row",
  backgroundColor: selected ? "#CFDBE9" : "",
  opacity: selected ? 1 : 0.3,
  padding: "2vh 1.5vw",
  alignItems: "center",
  fontSize: "0.6vw",
  h3: {
    margin: 0,
    fontSize: "0.9vw",
    fontWeight: "500"
  },
  ".emailContent": {
    position: "relative"
  },
  ".body": {
    lineHeight: 1.5
  }
}));

export const StyledEmailItemAvatar = styled(Avatar)(
  ({ large }: IEmailAvatarProps) => ({
    width: large ? "3.5vw" : "2.5vw",
    height: large ? "3.5vw" : "2.5vw",
    marginRight: large ? "1.25vw" : "0.6vw",
    img: { width: "initial" }
  })
);

export const StyledImageContainer = styled(Box)({
  position: "relative",
  overflowY: "hidden",
  ".overlayBlock": {
    position: "absolute",
    background:
      "linear-gradient(134.21deg, rgba(255, 255, 255, 0.6) 1.34%, rgba(255, 255, 255, 0.2) 100%)",
    backdropFilter: "blur(10px)",
    boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.1)"
  }
});

export const StyledLetterHeader = styled(Box)({
  margin: "1.25vw",
  div: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  h2: {
    padding: 0,
    fontSize: "1.25vw",
    fontFamily: "GraphikBold"
  },
  span: { marginLeft: "auto", fontSize: "0.6vw" }
});

export const StyledLetterImageContainer = styled(Stack)({
  padding: "0 2vw",
  fontSize: "0.7vw",
  color: "#979797",
  alignItems: "center",
  button: {
    boxShadow: "0px 15px 20px -5px rgb(0 86 179 / 30%)",
    backgroundColor: "#0056B3",
    padding: "0.75vw 2vw",
    borderRadius: "3vh",
    width: "fit-content",
    textTransform: "none",
    fontFamily: "GraphikBold"
  },
  ".imageBlock": {
    borderRadius: "0.75vh",
    overflow: "hidden"
  }
});

export const StyledMenuItem = styled(Box)(({ selected }: IEmailMenuProps) => ({
  fontSize: "0.9vw",
  fontWeight: "700",
  width: "100%",
  display: "flex",
  alignItems: "center",
  color: selected ? "#0056B3" : "#979797",
  svg: {
    marginRight: "1vw"
  },
  ".label": {
    backgroundColor: "#CFDBE9",
    fontSize: "0.9vw",
    color: "#0056B3",
    marginLeft: "auto"
  }
}));

export const StyledMenuItemList = styled(Stack)({
  paddingBottom: "3vh",
  fontFamily: "GraphikBold"
});

export const StyledRoundedTitle = styled(Box)({
  backgroundColor: "#CFDBE9",
  color: "#0056B3",
  height: "4vh",
  borderRadius: 40,
  fontSize: "0.9vw",
  fontWeight: "700",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "5vh",
  fontFamily: "GraphikBold"
});
