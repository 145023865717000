import {
  BOTTOM_BAR_INITIAL_SIZES,
  BUTTON_INITIAL_SIZES,
  FRONT_VIEW_MASK_SIZES,
  OFFER_LAYER_INITIAL_SIZES,
  PURCHASE_BUTTON_CONTAINER_INITIAL_SIZES,
  PURCHASE_BUTTON_INITIAL_SIZES,
  SLIDER_INITIAL_SIZES,
  STEPPER_INITIAL_SIZE,
  SUMMARY_BAR_INITIAL_SIZES,
  SUMMARY_BUTTONS_INITIAL_SIZES,
  TOP_BAR_INITIAL_SIZES
} from "constants/sizes";
import { useEffect, useState } from "react";

export const useSVGSizes = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    bottomBar: {
      width: screenWidth,
      height: screenWidth / BOTTOM_BAR_INITIAL_SIZES.HEIGHT_RATIO,
      arrows: {
        width: screenWidth / BOTTOM_BAR_INITIAL_SIZES.ARROWS.WIDTH_RATIO,
        height: screenWidth / BOTTOM_BAR_INITIAL_SIZES.ARROWS.HEIGHT_RATIO
      }
    },
    topBar: {
      width: screenWidth,
      height: screenWidth / TOP_BAR_INITIAL_SIZES.HEIGHT_RATIO
    },
    mask: {
      width: screenWidth / FRONT_VIEW_MASK_SIZES.WIDTH_RATIO,
      height: screenWidth / FRONT_VIEW_MASK_SIZES.HEIGHT_RATIO
    },
    summaryBar: {
      width: screenWidth,
      height: screenWidth / SUMMARY_BAR_INITIAL_SIZES.HEIGHT_RATIO,
      margin: screenWidth / SUMMARY_BAR_INITIAL_SIZES.MARGIN_RATIO
    },
    toggleButton: {
      marginBottom: screenWidth / BOTTOM_BAR_INITIAL_SIZES.BUTTON_MARGIN_BOTTOM,
      width: screenWidth / BUTTON_INITIAL_SIZES.WIDTH_RATIO,
      height:
        screenWidth /
        BUTTON_INITIAL_SIZES.WIDTH_RATIO /
        BUTTON_INITIAL_SIZES.HEIGHT_RATIO
    },
    offerLayer: {
      width: screenWidth / OFFER_LAYER_INITIAL_SIZES.WIDTH_RATIO,
      height: screenWidth / OFFER_LAYER_INITIAL_SIZES.HEIGHT_RATIO
    },
    purchaseBtn: {
      width: screenWidth / PURCHASE_BUTTON_INITIAL_SIZES.WIDTH_RATIO,
      height: screenWidth / PURCHASE_BUTTON_INITIAL_SIZES.HEIGHT_RATIO,
      xPos: screenWidth / PURCHASE_BUTTON_INITIAL_SIZES.X_POS_RATIO,
      yPos: screenWidth / PURCHASE_BUTTON_INITIAL_SIZES.Y_POS_RATIO
    },
    purchaseBtnContainer: {
      width: screenWidth / PURCHASE_BUTTON_CONTAINER_INITIAL_SIZES.WIDTH_RATIO,
      height: screenWidth / PURCHASE_BUTTON_CONTAINER_INITIAL_SIZES.HEIGHT_RATIO
    },
    slider: {
      width: screenWidth,
      height: screenWidth / SLIDER_INITIAL_SIZES.HEIGHT_RATIO
    },
    stepper: {
      width: screenWidth / STEPPER_INITIAL_SIZE.WIDTH_RATIO,
      lineHeight: screenWidth / STEPPER_INITIAL_SIZE.LINE_HEIGHT_RATIO,
      lineShortHeight:
        screenWidth / STEPPER_INITIAL_SIZE.LINE_SHORT_HEIGHT_RATIO,
      lineLongHeight: screenWidth / STEPPER_INITIAL_SIZE.LINE_LONG_HEIGHT_RATIO
    },
    summaryButtons: {
      radius: screenWidth / SUMMARY_BUTTONS_INITIAL_SIZES.RADIUS_RATIO,
      edgeBtn: {
        xPos:
          screenWidth / SUMMARY_BUTTONS_INITIAL_SIZES.EDGE_BUTTONS.X_POS_RATIO,
        yPos:
          screenWidth / SUMMARY_BUTTONS_INITIAL_SIZES.EDGE_BUTTONS.Y_POS_RATIO
      },
      middleBtn: {
        xPos:
          screenWidth /
          SUMMARY_BUTTONS_INITIAL_SIZES.MIDDLE_BUTTONS.X_POS_RATIO,
        yPos:
          screenWidth / SUMMARY_BUTTONS_INITIAL_SIZES.MIDDLE_BUTTONS.Y_POS_RATIO
      },
      centerBtn: {
        xPos:
          screenWidth /
          SUMMARY_BUTTONS_INITIAL_SIZES.CENTER_BUTTONS.X_POS_RATIO,
        yPos:
          screenWidth / SUMMARY_BUTTONS_INITIAL_SIZES.CENTER_BUTTONS.Y_POS_RATIO
      }
    }
  };
};
