import SummaryBarSVG from "assets/Summary/summaryBar.svg";
import { MENU_ITEMS } from "constants/menuItems";
import { APP_ROUTES } from "constants/routing";
import React from "react";
import { StyledBottomBarContainer, StyledMenuItem } from "../styles";

interface IProps {
  onNavigate: (route: string) => void;
  barWidth: number;
  barHeight: number;
  summaryButtons: any;
}

const Component = ({
  onNavigate,
  barWidth,
  barHeight,
  summaryButtons
}: IProps) => (
  <StyledBottomBarContainer>
    <SummaryBarSVG
      viewBox="0 0 1920 352"
      className="summaryBar"
      width={barWidth}
      height={barHeight}
    />
    {MENU_ITEMS.map(
      (menuItem, index) =>
        menuItem.route !== APP_ROUTES.SUMMARY && (
          <StyledMenuItem
            color="secondary"
            xPos={
              menuItem.isEdge
                ? summaryButtons.edgeBtn.xPos
                : menuItem.isMiddle
                ? summaryButtons.middleBtn.xPos
                : summaryButtons.centerBtn.xPos
            }
            yPos={
              menuItem.isEdge
                ? summaryButtons.edgeBtn.yPos
                : menuItem.isMiddle
                ? summaryButtons.middleBtn.yPos
                : summaryButtons.centerBtn.yPos
            }
            radius={summaryButtons.radius}
            side={index > 2 ? "right" : "left"}
            onClick={() => onNavigate(menuItem.route)}
          />
        )
    )}
  </StyledBottomBarContainer>
);

export default Component;
