import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICarConfigProps, initialState, IUnrealCarConfig } from "models/WebGLConfigurator";

export const WebGlConfiguratorSlice = createSlice({
  name: "carConfig",
  initialState,
  reducers: {
    setCarOptionConfig: (state, action: PayloadAction<ICarConfigProps>) => ({
      ...state,
      carConfig: { ...state.carConfig, ...action.payload }
    }),
    loadConfigurator: state => ({ ...state, isLoading: true }),
    loadConfiguratorSuccess: state => ({ ...state, isLoading: false }),
    resetCarConfig: () => initialState
  }
});

export function convertConfig(from: IUnrealCarConfig): ICarConfigProps {
  const mainConfig = require("../../config/mackevision-webgl-configurator.json");
  const carConfigs = mainConfig.webGL.carModels[0].carConfigs;
  const paint = carConfigs.find(
    (item: { configProperty: string, urlValue: string }) => item.configProperty === "carpaint" && item.urlValue == from.carpaint
  );
  const rims = carConfigs.find(
    (item: { configProperty: string, urlValue: string }) => item.configProperty === "rims" && item.urlValue == from.rims
  );
  const interior = carConfigs.find(
    (item: { configProperty: string, urlValue: string }) => item.configProperty === "interior" && item.urlValue == from.interior
  );
  const config: ICarConfigProps = {
    carpaint: paint,
    rims: rims,
    interior: interior
  };
  return config
}

export const {
  setCarOptionConfig,
  loadConfigurator,
  loadConfiguratorSuccess,
  resetCarConfig
} = WebGlConfiguratorSlice.actions;

export default WebGlConfiguratorSlice.reducer;
