import { Box, Stack } from "@mui/material";
import { SLIDES } from "constants/brochure";
import { IBrochureImagesProps } from "models/Brochure";
import React from "react";
import { carProperties } from "../config";
import {
  StyledPropContainer,
  StyledPropContent,
  StyledSlideCenterLabel,
  StyledSlideContainer
} from "./styles";

interface IProps {
  bgSrc: IBrochureImagesProps["frontViewImageURL"];
  mask: { width: number; height: number };
}

const FrontViewSlide = ({ bgSrc, mask }: IProps) => (
  <>
    <StyledSlideContainer
      bgSrc={bgSrc}
      count={SLIDES.FRONT_VIEW.count}
      type={SLIDES.FRONT_VIEW.type}
      mask={mask}
    />
    <StyledSlideCenterLabel count={SLIDES.FRONT_VIEW.count}>
      <Box className="title">
        The iconic <b>SUPER GT 900</b>
      </Box>
      <Box className="subtitle">
        The iconic <b>SUPER GT900</b>, unites beauty and performance to create a
        breathtaking driving experience.
      </Box>
    </StyledSlideCenterLabel>
    {/* +0.8 used for positioning label to bottom*/}
    <StyledSlideCenterLabel count={SLIDES.FRONT_VIEW.count + 0.8}>
      <Stack direction="row" spacing={"2vw"}>
        {carProperties.map(carProp => (
          <Stack>
            <Box className="subtitle">{carProp.title}</Box>
            <StyledPropContainer>
              <StyledPropContent>
                {carProp.icon}
                {carProp.value}
              </StyledPropContent>
              <span>{carProp.measure}</span>
            </StyledPropContainer>
          </Stack>
        ))}
      </Stack>
    </StyledSlideCenterLabel>
  </>
);

export default FrontViewSlide;
